import type { IHttpClient } from '@/shared/api/clients/HttpClient';

export abstract class HttpService {
  protected httpClient: IHttpClient;

  protected baseUrl: string;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }
}
