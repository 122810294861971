import { type AuftraggeberInanspruchnahmeWorkflowSchema } from '@/lang/types/types.ts';

export const inanspruchnahmeWorkflow: AuftraggeberInanspruchnahmeWorkflowSchema = {
  contentHeader: 'Bürgschaft in Anspruch nehmen',
  banner: {
    erfolg: {
      text: 'Die Inanspruchnahme wurde beim Bürgen übermittelt.',
    },
  },
  steps: {
    details: {
      title: 'Inanspruchnahme beschreiben',
      beschreibung:
        'Mit dieser Funktion können Sie Ihre Inanspruchnahme an den Bürgen übermitteln. Für die Inanspruchnahme gelten die mit dem Bürgen vereinbarten Voraussetzungen, für deren Einhaltung allein Sie verantwortlich sind. ',
      footer:
        'Falls nach Ihrem Wissen erforderlich, können Sie hier Unterlagen zur Konkretisierung Ihrer Inanspruchnahme zu dieser Bürgschaft hochladen. Sie können bis zu 10 Dateien (z.B. Anschreiben) mit maximal 10 MB pro Datei hochladen. Mehrere Dokumente können zu einer Datei zusammengefasst werden.',
      footerLegalAddon:
        'Der Bürge behält sich vor, bei Bedarf weitere Unterlagen und Informationen anzufordern.',
      form: {
        grundInanspruchname: {
          label: 'Begründung der Inanspruchnahme',
          placeholder:
            'Bitte legen Sie den Sachverhalt dar, warum die Bürgschaft in Anspruch genommen wird...',
        },
        hoeheInanspruchname: {
          label: 'Höhe der Inanspruchnahme',
        },
        dokumente: {
          label: 'Dokumente',
        },
      },
    },
    ansprechpartner: {
      title: 'Ansprechpartner angeben',
      beschreibung:
        'Geben Sie für mögliche Rückfragen des Bürgen die Kontaktdaten eines Ansprechpartners sowie ggf. das interne Aktenzeichen an. Falls die Anschrift des Ansprechpartners von der Adresse auf der Bürgschaft abweicht, können Sie diese hier auch eintragen.',
      rows: {
        ansprechpartner: {
          title: 'Ansprechpartner',
          meineKontaktdatenEintragen: 'Meine Kontaktdaten eintragen',
        },
        aktenzeichen: {
          title: 'Aktenzeichen',
        },
        postadresse: {
          title: 'Anschrift des Ansprechpartners',
        },
      },
    },
    pruefen: {
      title: 'Eingaben prüfen',
      beschreibung: 'Bitte überprüfen Sie die von Ihnen angegebenen Daten.',
      rows: {
        inanspruchnahme: {
          title: 'Inanspruchnahme',
          subtitles: {
            hoehe: 'Höhe der Inanspruchnahme',
            aktenzeichen: 'Aktenzeichen',
            grund: 'Grund der Inanspruchnahme',
            ansprechpartner: 'Ansprechpartner',
            anschrift: 'Anschrift',
            unterlagen: 'Unterlagen',
          },
        },
        nutzererklaerung: {
          title: 'Nutzererklärung',
          fehler: 'Bitte stimmen Sie der Nutzererklärung zu, um den Prozess fortzuführen',
        },
      },
    },
  },
};
