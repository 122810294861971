/**
 * Bündelt alle Domänenobjekte zu Bürgschafen. Dabei gibt es Referenzen zu Partner (z.B. wenn es um Ansprechpartner
 * geht) und zu Dokument (z.B. zu Template).
 */
import isEmpty from 'lodash/isEmpty';
import isEqualWith from 'lodash/isEqualWith';
import isString from 'lodash/isString';
import type { ErinnerungObject } from '@/components/erinnerung/types/ErinnerungObject';
import type { KommentarObject } from '@/components/kommentare/types/KommentarObject';
import type { AbtretungsStatus } from '@/shared/Buergschaften/types';
import i18n from '@/lang/i18nPlugin';
import type {
  PruefentscheidungInformationen,
  PruefungDto,
} from '@/shared/Pruefungen/types/pruefung.ts';
import type { Inanspruchnahme } from '@/shared/Buergschaften/inanspruchnahme/types.ts';
import type { Postadresse } from './Partner';
import { createEmptyPostadresse } from './Partner';

import type { Baurecht } from './DokumentBGT';

export enum AuftraggeberTyp {
  JURISTISCH = 'JURISTISCH',
  OEFFENTLICH = 'OEFFENTLICH',
  NATUERLICH = 'NATUERLICH',
}

export interface BuergschaftMetaDaten {
  loeschbar?: boolean;
  bearbeitbar?: boolean;
}
/** Die Bürgschaft - zentrales Business-Objekt der Bürgschaftservice-Plattform. Bedarf eigentlich keiner Erklärung */
export interface Bürgschaft {
  bürgschaftId?: number;
  auftragId?: number;
  version?: number;
  auftragnehmer: Auftragnehmer;
  auftraggeber: Auftraggeber;
  auftraggeberGemaessVertrag: AuftraggeberGemaessVertrag;
  bürge?: Bürge;
  bürgschaftstemplateId?: string;
  bürgschaftIdBürge: string;
  ausstellungsdatumBürge: string;
  voraussichtlichesAblaufdatum: string;
  bürgschaftsart: Bürgschaftsart;
  befristungsdatum?: string;
  befristung?: boolean;
  bürgschaftsbetrag: string; // Hier wäre ein spezieller Datentyp gut
  bürgschaftsbetragWorte?: string;
  verminderterBürgschaftsbetrag?: string; // Hier wäre ein spezieller Datentyp gut
  währungskennzeichen?: string;
  vertragsnummer: string;
  vertragsdatum: string;
  bauleistung: string;
  bauadresse?: Postadresse;
  bauvorhaben?: string;
  zusatzklauseln?: Zusatzklausel;
  bürgschaftsstatus?: Bürgschaftsstatus;
  enthaftung?: Enthaftung;
  teilenthaftung?: Teilenthaftung;
  teilenthaftungen?: Teilenthaftung[];
  bürgschaftskorrektur?: Bürgschaftskorrektur;
  pruefungen?: PruefungDto[];
  inanspruchnahmen?: Inanspruchnahme[];
  baurecht?: Baurecht;
  herkunft?: Herkunft;
  herkunftVereinfacht?: HerkunftVereinfacht;
  textverifizierung?: Textverifizierung;
  bausumme?: string;
  bausummeprozent?: string;
  buergschaftAuftraggeberdaten?: BuergschaftUnternehmensdaten;
  buergschaftAuftragnehmerdaten?: BuergschaftUnternehmensdaten;
  aktuelleBuergschaftUnternehmensdaten?: BuergschaftUnternehmensdaten;
  metadaten?: BuergschaftMetaDaten;
  lieferkanal?: BuergschaftsLieferkanal;
  zahlungAufErstesAnfordern?: boolean;
}

/** Die Bürgschaft - zentrales Business-Objekt der Bürgschaftservice-Plattform. Bedarf eigentlich keiner Erklärung */
export interface BuergschaftGrunddaten {
  auftragnehmer: Auftragnehmer;
  auftraggeber: Auftraggeber;
  buerge?: Bürge;
  buergschaftstemplateId?: string;
  voraussichtlichesAblaufdatum: string;
  buergschaftsart: Bürgschaftsart;
  befristungsdatum?: string;
  befristung?: boolean;
  buergschaftsbetrag: string;
  vertragsnummer: string;
  vertragsdatum: string;
  bauleistung: string;
  bauvorhaben?: Bauvorhaben;
  baurecht?: Baurecht;
  bausumme?: string;
  bausummeprozent?: string;
  ausstellungsdatumBuerge?: string;
  buergschaftIdBuerge?: string;
  buergschaftId?: number;
  korrektur?: Bürgschaftskorrektur;
}

export interface FrontendListenBuergschaft {
  buergschaftId?: number;
  buergschaftsstatus?: Bürgschaftsstatus;
  buergeId?: string;
  auftraggeberId?: string;
  auftraggeberName?: string;
  auftraggeberPostleitzahl?: string;
  auftraggeberOrt?: string;
  auftragnehmerId?: string;
  auftragnehmerName?: string;
  auftragnehmerPostleitzahl?: string;
  auftragnehmerOrt?: string;
  buergschaftsart?: Bürgschaftsart;
  voraussichtlichesAblaufdatum?: string;
  ausstellungsdatumBuerge?: string;
  buergschaftsbetrag?: string;
  verminderterBuergschaftsbetrag?: string;
  inanspruchnahmenVorhanden?: boolean;
  buergschaftsIdBuerge?: string;
  siegelAnzeigen?: boolean;
  projektId?: string;
  projektName?: string;
}

export interface FrontendListenAbtretungBuergschaft {
  buergschaftId?: number;
  buergeId?: string;
  status: AbtretungsStatus;
  auftraggeberName?: string;
  auftraggeberPostleitzahl?: string;
  auftraggeberOrt?: string;
  auftragnehmerName?: string;
  auftragnehmerPostleitzahl?: string;
  auftragnehmerOrt?: string;
  buergschaftsart?: Bürgschaftsart;
  ausstellungsdatumBuerge?: string;
  buergschaftsbetrag?: string;
  verminderterBuergschaftsbetrag?: string;
}

export interface BuergschaftUnternehmensdaten {
  internerVermerk?: string;
  buergschaftId?: number;
  rolle?: AufruferRolle;
  organisation?: string;
  version: string;
}

export interface BuergschaftDetailsFuerAufgabenListe {
  buergschaftId?: number;
  auftragnehmerId?: string;
  auftraggeberId?: string;
}

export interface Versandadresse {
  name: string;
  zustellanweisung?: string;
  postadresse: Postadresse;
}

/** Der Auftragnehmer ist der Schuldner im Bürgschaftsvertrag */
export interface Bauvorhaben {
  adresse?: Postadresse;
  freitext?: string;
}

export enum BauvorhabenTyp {
  ADRESSE = 'Adresse eingeben',
  FREITEXT = 'Ort beschreiben',
}

export function createEmptyAnsprechpartner(): Ansprechpartner {
  return {
    email: '',
    telefonnummer: null,
    vorname: '',
    nachname: '',
  };
}

export function createEmptyAuftragnehmer(): Auftragnehmer {
  return {
    auftragnehmerId: null,
    fremdId: '',
    name: '',
    postadresse: createEmptyPostadresse(),
    ansprechpartner: createEmptyAnsprechpartner(),
  };
}

export function createEmptyAuftraggeber(): Auftraggeber {
  return {
    auftraggeberId: '',
    name: '',
    postadresse: createEmptyPostadresse(),
    oeffentlicherAuftraggeber: false,
    vertretungsformel: [],
  };
}

export function createEmptyAuftraggeberGemaessVertrag(): AuftraggeberGemaessVertrag {
  return {
    auftraggeberGemaessVertragId: '',
    name: '',
    postadresse: createEmptyPostadresse(),
  };
}

export function createEmptyBürge(): Bürge {
  return {
    id: '',
    vertragsart: null,
  };
}

export function createEmptyBürgschaft(): Bürgschaft {
  return {
    auftraggeber: createEmptyAuftraggeber(),
    auftragnehmer: createEmptyAuftragnehmer(),
    auftraggeberGemaessVertrag: createEmptyAuftraggeberGemaessVertrag(),
    bürgschaftIdBürge: '',
    ausstellungsdatumBürge: '',
    voraussichtlichesAblaufdatum: '',
    bürgschaftsbetrag: '',
    bürge: createEmptyBürge(),
    bürgschaftsart: undefined,
    vertragsdatum: '',
    vertragsnummer: '',
    befristungsdatum: '',
    bauleistung: '',
    bauvorhaben: '',
    bauadresse: createEmptyPostadresse(),
    bürgschaftskorrektur: null,
    zahlungAufErstesAnfordern: false,
    bürgschaftsstatus: null,
  };
}

/**
 * Helper-Methoden zur korrekten Formatierung des Bürgschaftsbetrags
 */

export function formatiereBürgschaftsbetragMitPunkt(buergschaftsbetrag: string): string {
  const formatierterBetrag = buergschaftsbetrag.toString().replace(/\./g, ''); // alle Punkte entfernen
  return formatierterBetrag.replace(/,/g, '.'); // alle Kommata durch Punkte ersetzen
}

export function formatiereBürgschaftsbetragMitKomma(buergschaftsbetrag: string): string {
  // Das Feld Bürgschaftsbetrag wird nicht korrekt vorbelegt, falls bei der manuellen Anlage (einer Bürgschaft über die Plattform)
  // der Bürgschaftsbetrag ohne Nachkommastellen ODER mit genau einer Nachkommastelle gesetzt wird (z.B. 198 oder 198,1)
  // Bei der Korrektur muss der Bürgschaftsbetrag ausgelesen und gemäß TrustlogInputBetragField gesetzt werden und ggf .mit Nullen gefüllt werden

  // Der Bürgschaftsbetrag ist beim Auslesen aus dem Backend vom Typ number und nicht vom Typ string, daher findet hier ein Type-Casting statt
  const formatierterBetrag = buergschaftsbetrag.toString().replace(/\./g, ','); // alle Punkte durch Kommata ersetzen
  return formatierterBetrag.replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Tausendertrennzeichen setzen
}

export function setBürgschaftsbetragNachkommastellen(buergschaftsbetrag: string): string {
  let formatierterBetrag = buergschaftsbetrag;
  if (!formatierterBetrag.includes(',')) {
    formatierterBetrag += ',00';
  } else {
    const anzahlZiffernNachDemKomma =
      formatierterBetrag.length - formatierterBetrag.indexOf(',') - 1;
    switch (anzahlZiffernNachDemKomma) {
      case 0:
        formatierterBetrag += '00';
        break;
      case 1:
        formatierterBetrag += '0';
        break;
      default:
        break;
    }
  }
  return formatierterBetrag;
}

export function formatiereBürgschaftsbetrag(buergschaftsbetrag: string): string {
  return formatiereBürgschaftsbetragMitPunkt(
    setBürgschaftsbetragNachkommastellen(formatiereBürgschaftsbetragMitKomma(buergschaftsbetrag)),
  );
}

/**
 * Main check if two Bürgschaften are exact equal
 * TODO: prüfen ob die mutation der Objekte hier ein gewolltes Verhalten ist
 */
export function sindBürgschaftenGleich(b1: Bürgschaft, b2: Bürgschaft): boolean {
  // Step 1: prepare data (use same format) for comparing

  // eslint-disable-next-line no-param-reassign
  b1.bürgschaftsbetrag = formatiereBürgschaftsbetrag(b1.bürgschaftsbetrag);
  // eslint-disable-next-line no-param-reassign
  b2.bürgschaftsbetrag = formatiereBürgschaftsbetrag(b2.bürgschaftsbetrag);
  // eslint-disable-next-line no-param-reassign
  if (b1.bauadresse === null) b1.bauadresse = createEmptyPostadresse();
  // eslint-disable-next-line no-param-reassign
  if (b2.bauadresse === null) b2.bauadresse = createEmptyPostadresse();
  // Step 2: Compare two Object with customizing the Values String with length of 0 and null
  return isEqualWith(b1, b2, (w1, w2) => {
    if (isString(w1) || isString(w2)) {
      if (isEmpty(w1) && isEmpty(w2)) {
        return true;
      }
      return w1 === w2;
    }
    return undefined;
  });
}

export enum BuergschaftBearbeitungArt {
  NICHTS = 'NICHTS',
  LOESCHEN = 'LOESCHEN',
  ENTHAFTEN = 'ENTHAFTEN',
  INTERNER_VERMERK = 'INTERNER_VERMERK',
  ABLAUFDATUM = 'ABLAUFDATUM',
  STATUS = 'STATUS',
}

export enum BuergschaftsdokumentBearbeitungArt {
  NICHTS = 'NICHTS',
  LOESCHEN = 'LOESCHEN',
}

/** Status, die eine Bürgschaft durchläuft */
export enum Bürgschaftsstatus {
  /** Entwurf */
  ENT = 'ENT',

  /** Entwurf storniert */
  STO = 'STO',

  /** Ausstehend */
  AUS = 'AUS',

  /** Ungeprüft */
  UP = 'UP',

  /** Aktiv */
  AKT = 'AKT',

  /** In Korrektur */
  IK = 'IK',

  /** Umtausch */
  UT = 'UT',

  /** Abgelehnt beantragt */
  ABB = 'ABB',

  /** Abgelehnt */
  AB = 'AB',

  /** Teilenthaftung beantragt */
  TEB = 'TEB',

  /** Inanspruchnahme beantragt */
  ANB = 'ANB',

  /** Inanspruchnahme */
  AN = 'AN',

  /** Enthaftung beantragt */
  ZGBB = 'ZGBB',

  /** Enthaftet */
  ZGB = 'ZGB',

  /** Abgetreten: veranlasst, akzeptiert  */
  ABGETRETEN = 'ABGETRETEN',
}

/** Funktion, die die Bürgschaftsstatus-Anzeigetexte basierend auf einer Bedingung zurückgibt */
export function getBuergschaftStatusAnzeigetextTabelleBuergeMap(
  isKurzformAbgelehnt: boolean = false,
): Map<Bürgschaftsstatus, string> {
  const abgelehntBeguenstigter = isKurzformAbgelehnt ? 'Abgelehnt' : 'Abgelehnt (Begünstigter)';
  const abgelehntBuerge = isKurzformAbgelehnt ? 'Abgelehnt' : 'Abgelehnt (Zweitprüfer)';
  return new Map([
    [Bürgschaftsstatus.ENT, 'Entwurf'],
    [Bürgschaftsstatus.STO, abgelehntBuerge],
    [Bürgschaftsstatus.UP, 'Ungeprüft'],
    [Bürgschaftsstatus.AUS, 'Ausstehend'],
    [Bürgschaftsstatus.AKT, 'Aktiv'],
    [Bürgschaftsstatus.IK, 'Korrektur'],
    [Bürgschaftsstatus.TEB, 'Aktiv'],
    [Bürgschaftsstatus.ANB, 'Aktiv'],
    [Bürgschaftsstatus.ZGBB, 'Enthaftet'],
    [Bürgschaftsstatus.UT, 'Umtausch'],
    [Bürgschaftsstatus.ZGB, 'Enthaftet'],
    [Bürgschaftsstatus.ABB, abgelehntBeguenstigter],
    [Bürgschaftsstatus.AB, abgelehntBeguenstigter],
    [Bürgschaftsstatus.ABGETRETEN, 'Abgetreten'],
  ]);
}

/** Map, die den Bürgschaftsstatus auf einen Text für die Anzeige abbildet - für Auftraggeber */
export const BürgschaftStatusAnzeigetextMap: Map<Bürgschaftsstatus, string> = new Map([
  [Bürgschaftsstatus.ENT, 'Entwurf'],
  [Bürgschaftsstatus.STO, 'Abgelehnt'],
  [Bürgschaftsstatus.UP, 'Ungeprüft'],
  [Bürgschaftsstatus.AKT, 'Aktiv'],
  [Bürgschaftsstatus.IK, 'In Korrektur'],
  [Bürgschaftsstatus.TEB, 'Aktiv'],
  [Bürgschaftsstatus.ANB, 'Aktiv'],
  [Bürgschaftsstatus.ZGBB, 'Enthaftet'],
  [Bürgschaftsstatus.UT, 'Umtausch'],
  [Bürgschaftsstatus.AN, 'Inanspruchnahme'],
  [Bürgschaftsstatus.ZGB, 'Enthaftet'],
  [Bürgschaftsstatus.ABB, 'Abgelehnt'],
  [Bürgschaftsstatus.AB, 'Abgelehnt'],
  [Bürgschaftsstatus.ABGETRETEN, 'Abgetreten'],
]);

/** Map, die den Bürgschaftsstatus auf einen Text für die Anzeige abbildet - für trustlog-Mitarbeiter */
export const BürgschaftStatusAnzeigetextMapTrustlog: Map<Bürgschaftsstatus, string> = new Map([
  [Bürgschaftsstatus.UP, 'Ungeprüft'],
  [Bürgschaftsstatus.STO, 'Abgelehnt'],
  [Bürgschaftsstatus.AUS, 'Ausstehend'],
  [Bürgschaftsstatus.AKT, 'Aktiv'],
  [Bürgschaftsstatus.IK, 'In Korrektur'],
  [Bürgschaftsstatus.TEB, 'Aktiv'],
  [Bürgschaftsstatus.ANB, 'Aktiv'],
  [Bürgschaftsstatus.ZGBB, 'Enthaftet'],
  [Bürgschaftsstatus.UT, 'Umtausch'],
  [Bürgschaftsstatus.AN, 'Inanspruchnahme'],
  [Bürgschaftsstatus.ZGB, 'Enthaftet'],
  [Bürgschaftsstatus.ABB, 'Abgelehnt'],
  [Bürgschaftsstatus.AB, 'Abgelehnt'],
  [Bürgschaftsstatus.ABGETRETEN, 'Abgetreten'],
]);

export interface Ansprechpartner {
  email: string;
  telefonnummer: string;
  vorname: string;
  nachname: string;
}

export interface BuergschaftUnternehmenZuordnen {
  unternehmenId: string;
  buergschaftVersion: number;
  unternehmenRolle: UnternehmensTypEntscheidung;
}

export interface Unternehmen {
  unternehmenId?: string;
  name: string;
  postadresse: Postadresse;
}

export enum UnternehmensTypEntscheidung {
  AUFTRAGGEBER = 'AUFTRAGGEBER',
  AUFTRAGNEHMER = 'AUFTRAGNEHMER',
}

/** Der Auftragnehmer ist der Schuldner im Bürgschaftsvertrag */
export interface Auftragnehmer {
  auftragnehmerId?: string;
  fremdId?: string;
  name: string;
  postadresse: Postadresse;
  anzahlToechter?: number;
  ansprechpartner?: Ansprechpartner;
}

/** Der Auftraggeber ist der Gläubiger im Bürgschaftsvertrag */
export interface Auftraggeber {
  auftraggeberId: string;
  name: string;
  postadresse: Postadresse;
  oeffentlicherAuftraggeber?: boolean;
  vertretungsformel?: string[];
  typ?: AuftraggeberTyp;
  versandadresse?: Versandadresse;
}

/** Der Auftraggeber ist der Gläubiger im Bürgschaftsvertrag */
export interface AuftraggeberGemaessVertrag {
  auftraggeberGemaessVertragId: string;
  name: string;
  postadresse: Postadresse;
}

// Definieren des Enums für vertragsart
export enum Vertragsart {
  LIGHT = 'LIGHT',
  REGULAR = 'REGULAR',
}

/** Der Bürge der Bürgschaft */
export interface Bürge {
  id: string;
  vertragsart?: Vertragsart;
}

/**
 * Gibt Auskünft darüber, wie die Bürgschaft in's System gekommen ist. Wird nur für die wenigsten Use-Cases benötigt.
 * Siehe auch {@link HerkunftVereinfacht}, für eine reduzierte Variante, die vornehmlich zu verwenden ist!
 */
export enum Herkunft {
  /** Erstellt in TL */
  ETL = 'ETL',

  /** Manuell in TL */
  MTL = 'MTL',

  /** Importiert für Auftragnehmer */
  IAN = 'IAN',

  /** Importiert von Auftragnehmer */
  IVAN = 'IVAN',

  /** Importiert für Auftraggeber */
  IAG = 'IAG',

  /** Manuell Importiert durch Auftraggeber */
  MDA = 'MDA',
}

/**
 * Reduziertes Set von Herkunft, das zwischen neuen digitalen Bürgschaften und replizierten Altbürgschaften unterscheidet.
 * Sollte für die meisten Use-Cases ausreichen und ist daher bevorzugt zu verwenden (gegenüber {@link Herkunft}).
 */
export enum HerkunftVereinfacht {
  ERSTELLT_IN_TL = 'ERSTELLT_IN_TL',
  REPLIZIERT = 'REPLIZIERT',
  IMPORTIERT = 'IMPORTIERT',
}
export enum Abtretungsstatus {
  VERANLASST = 'VERANLASST',
  AKZEPTIERT = 'AKZEPTIERT',
  ABGELEHNT = 'ABGELEHNT',
}

/** Eine Bürgschaft ist von einem bestimmten Typ - der Bürgschaftsart */
export enum Bürgschaftsart {
  // Vorhandene Werte
  MAENGELANSPRUECHE = 'MAB', // Mängelansprüchebürgschaft
  VERTRAGSERFUELLUNG = 'VEB', // Vertragserfüllungsbürgschaft
  ANVORAUSZAHLUNG = 'AVB', // An- & Vorausszahlungsbürgschaft
  AUSFUEHRUNG = 'AUB', // Ausführungsbürgschaft

  // Neue Werte
  ALLGEMEIN = 'ALG', // Allgemein
  ANZAHLUNG = 'ANZ', // Anzahlungen
  ARBEITSZEITKONTEN = 'AZK', // Arbeitszeitkontenbürgschaft
  AUSLAND = 'AUL', // Auslandsbürgschaft
  AUSSCHÜTTUNG = 'ASB', // Ausschüttungsbürgschaft
  BAUHANDWERKERSICHERUNG = 'BHS', // Bauhandwerkersicherungsbürgschaft
  BIETUNG = 'BIE', // Bietungsbürgschaft
  BIMSCHG = 'BIM', // BImSchG-Bürgschaft
  BRANNTWEIN = 'BRA', // Branntweinbürgschaft
  DECKUNGSHAFTUNGSRUECKLASS = 'DHG', // Deckungs-/Haftungsrücklass
  DECKUNGSRUECKLASS = 'DEC', // Deckungsrücklass
  ERSCHLIESSUNG = 'ERS', // Erschließungsbürgschaft
  FERTIGSTELLUNG = 'FHG', // Fertigstellungs-/Haftungsrücklass
  FERTIGSTELLUNGGARANTIE = 'FER', // Fertigstellungsgarantie
  FRACHT = 'FRA', // Frachtbürgschaft
  GARANTIE = 'GAR', // Garantie
  GEWAEHRLEISTUNG = 'GWG', // Gewährleistungsgarantie
  HAFTUNG = 'HAF', // Haftungsrücklass
  IATA = 'IAT', // IATA-Bürgschaft
  LIEFERANTEN = 'LIE', // Lieferantenbürgschaften
  MABV = 'MVB', // MaBV-Bürgschaft
  MIET = 'MIE', // Mietbürgschaft
  MINERALOEL = 'MIN', // Mineralölbürgschaft
  PACHT = 'PAC', // Pachtbürgschaft
  PROZESS = 'PRO', // Prozessbürgschaft
  REKULTIVIERUNG = 'REK', // Rekultivierungsbürgschaft
  RUECK = 'RUE', // Rückbürgschaft
  SONSTIGE = 'SON', // Sonstige Bürgschaft
  SONSTIGEGARANTIE = 'SOG', // Sonstige Garantie
  TANKKARTEN = 'TAN', // Tankkartenbürgschaft
  VERTRAGSERFUELLUNGGARANTIE = 'VEG', // Vertragserfüllungsgarantie
  ZAHLUNG = 'ZAH', // Zahlungsbürgschaft
  ZOLL = 'ZOL', // Zollbürgschaft
}

export const aktiveBuergschaftsarten = [
  Bürgschaftsart.MAENGELANSPRUECHE,
  Bürgschaftsart.VERTRAGSERFUELLUNG,
  Bürgschaftsart.ANVORAUSZAHLUNG,
  Bürgschaftsart.AUSFUEHRUNG,
];
export const aktiveBuergschaftsartenTL = [
  Bürgschaftsart.MAENGELANSPRUECHE,
  Bürgschaftsart.VERTRAGSERFUELLUNG,
  Bürgschaftsart.ANVORAUSZAHLUNG,
  Bürgschaftsart.AUSFUEHRUNG,
  Bürgschaftsart.ALLGEMEIN,
  Bürgschaftsart.MIET,
];

export const aktiveBuergschaftsartenImport = [
  ...aktiveBuergschaftsarten,
  Bürgschaftsart.BAUHANDWERKERSICHERUNG,
  Bürgschaftsart.BIETUNG,
  Bürgschaftsart.MABV,
  Bürgschaftsart.MIET,
  Bürgschaftsart.SONSTIGE,
];

/** Bildet die Bürgschaftsart auf einen Anzeigetext ab - mit dem Suffix "bürgschaft" */
export const BürgschaftsartAnzeigetextMapBürgschaft: Map<Bürgschaftsart, string> = new Map([
  // Vorhandene Einträge
  [
    Bürgschaftsart.MAENGELANSPRUECHE,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[0]'),
  ],
  [
    Bürgschaftsart.VERTRAGSERFUELLUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[1]'),
  ],
  [
    Bürgschaftsart.ANVORAUSZAHLUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[2]'),
  ],
  [
    Bürgschaftsart.AUSFUEHRUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[3]'),
  ],

  // Neue Werte
  [
    Bürgschaftsart.ANZAHLUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[4]'),
  ],
  [
    Bürgschaftsart.ARBEITSZEITKONTEN,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[5]'),
  ],
  [
    Bürgschaftsart.AUSLAND,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[6]'),
  ],
  [
    Bürgschaftsart.AUSSCHÜTTUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[7]'),
  ],
  [
    Bürgschaftsart.BAUHANDWERKERSICHERUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[8]'),
  ],
  [
    Bürgschaftsart.BIETUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[9]'),
  ],
  [
    Bürgschaftsart.BIMSCHG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[10]'),
  ],
  [
    Bürgschaftsart.BRANNTWEIN,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[11]'),
  ],
  [
    Bürgschaftsart.DECKUNGSHAFTUNGSRUECKLASS,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[12]'),
  ],
  [
    Bürgschaftsart.DECKUNGSRUECKLASS,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[13]'),
  ],
  [
    Bürgschaftsart.ERSCHLIESSUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[14]'),
  ],
  [
    Bürgschaftsart.FERTIGSTELLUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[15]'),
  ],
  [
    Bürgschaftsart.FERTIGSTELLUNGGARANTIE,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[16]'),
  ],
  [
    Bürgschaftsart.FRACHT,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[17]'),
  ],
  [
    Bürgschaftsart.GARANTIE,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[18]'),
  ],
  [
    Bürgschaftsart.GEWAEHRLEISTUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[19]'),
  ],
  [
    Bürgschaftsart.HAFTUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[20]'),
  ],
  [
    Bürgschaftsart.IATA,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[21]'),
  ],
  [
    Bürgschaftsart.LIEFERANTEN,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[22]'),
  ],
  [
    Bürgschaftsart.MABV,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[23]'),
  ],
  [
    Bürgschaftsart.MIET,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[24]'),
  ],
  [
    Bürgschaftsart.MINERALOEL,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[25]'),
  ],
  [
    Bürgschaftsart.PACHT,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[26]'),
  ],
  [
    Bürgschaftsart.PROZESS,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[27]'),
  ],
  [
    Bürgschaftsart.REKULTIVIERUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[28]'),
  ],
  [
    Bürgschaftsart.RUECK,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[29]'),
  ],
  [
    Bürgschaftsart.SONSTIGE,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[30]'),
  ],
  [
    Bürgschaftsart.SONSTIGEGARANTIE,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[31]'),
  ],
  [
    Bürgschaftsart.TANKKARTEN,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[32]'),
  ],
  [
    Bürgschaftsart.VERTRAGSERFUELLUNGGARANTIE,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[33]'),
  ],
  [
    Bürgschaftsart.ZAHLUNG,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[34]'),
  ],
  [
    Bürgschaftsart.ZOLL,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[35]'),
  ],
  [
    Bürgschaftsart.ALLGEMEIN,
    i18n.global.t('avalgeber.buergschaftstexte.liste.filter.filterOptions.avalart[36]'),
  ],
]);

/** Bildet die Bürgschaftsart auf einen Anzeigetext ab - mit dem Suffix "bürgschaften" */
export const BürgschaftsartAnzeigetextMapBürgschaftPlural: Map<Bürgschaftsart, string> = new Map([
  // Vorhandene Einträge
  [Bürgschaftsart.MAENGELANSPRUECHE, 'Mängelansprüchebürgschaften'],
  [Bürgschaftsart.VERTRAGSERFUELLUNG, 'Vertragserfüllungsbürgschaften'],
  [Bürgschaftsart.ANVORAUSZAHLUNG, 'An- & Vorauszahlungsbürgschaften'],
  [Bürgschaftsart.AUSFUEHRUNG, 'Ausführungsbürgschaften'],

  // Neue Werte
  [Bürgschaftsart.ANZAHLUNG, 'Anzahlungsbürgschaften'],
  [Bürgschaftsart.ARBEITSZEITKONTEN, 'Arbeitszeitkontenbürgschaften'],
  [Bürgschaftsart.AUSLAND, 'Auslandsbürgschaften'],
  [Bürgschaftsart.AUSSCHÜTTUNG, 'Ausschüttungsbürgschaften'],
  [Bürgschaftsart.BAUHANDWERKERSICHERUNG, 'Bauhandwerkersicherungsbürgschaften'],
  [Bürgschaftsart.BIETUNG, 'Bietungsbürgschaften'],
  [Bürgschaftsart.BIMSCHG, 'BImSchG-Bürgschaften'],
  [Bürgschaftsart.BRANNTWEIN, 'Branntweinbürgschaften'],
  [Bürgschaftsart.DECKUNGSHAFTUNGSRUECKLASS, 'Deckungs-/Haftungsrücklassbürgschaften'],
  [Bürgschaftsart.DECKUNGSRUECKLASS, 'Deckungsrücklassbürgschaften'],
  [Bürgschaftsart.ERSCHLIESSUNG, 'Erschließungsbürgschaften'],
  [Bürgschaftsart.FERTIGSTELLUNG, 'Fertigstellungs-/Haftungsrücklassbürgschaften'],
  [Bürgschaftsart.FERTIGSTELLUNGGARANTIE, 'Fertigstellungsgarantiebürgschaften'],
  [Bürgschaftsart.FRACHT, 'Frachtbürgschaften'],
  [Bürgschaftsart.GARANTIE, 'Garantiebürgschaften'],
  [Bürgschaftsart.GEWAEHRLEISTUNG, 'Gewährleistungsgarantiebürgschaften'],
  [Bürgschaftsart.HAFTUNG, 'Haftungsrücklassbürgschaften'],
  [Bürgschaftsart.IATA, 'IATA-Bürgschaften'],
  [Bürgschaftsart.LIEFERANTEN, 'Lieferantenbürgschaften'],
  [Bürgschaftsart.MABV, 'MaBV-Bürgschaften'],
  [Bürgschaftsart.MIET, 'Mietbürgschaften'],
  [Bürgschaftsart.MINERALOEL, 'Mineralölbürgschaften'],
  [Bürgschaftsart.PACHT, 'Pachtbürgschaften'],
  [Bürgschaftsart.PROZESS, 'Prozessbürgschaften'],
  [Bürgschaftsart.REKULTIVIERUNG, 'Rekultivierungsbürgschaften'],
  [Bürgschaftsart.RUECK, 'Rückbürgschaften'],
  [Bürgschaftsart.SONSTIGE, 'Sonstige Bürgschaften'],
  [Bürgschaftsart.SONSTIGEGARANTIE, 'Sonstige Garantiebürgschaften'],
  [Bürgschaftsart.TANKKARTEN, 'Tankkartenbürgschaften'],
  [Bürgschaftsart.VERTRAGSERFUELLUNGGARANTIE, 'Vertragserfüllungsgarantiebürgschaften'],
  [Bürgschaftsart.ZAHLUNG, 'Zahlungsbürgschaften'],
  [Bürgschaftsart.ZOLL, 'Zollbürgschaften'],
]);

/** Bildet die Bürgschaftsart auf einen Anzeigetext ab - ohne das Suffix "bürgschaft" */
export const BürgschaftsartAnzeigetextMap: Map<Bürgschaftsart, string> = new Map([
  // Vorhandene Einträge
  [Bürgschaftsart.MAENGELANSPRUECHE, i18n.global.t('shared.avalartAnzeigetextMap[0]')],
  [Bürgschaftsart.VERTRAGSERFUELLUNG, i18n.global.t('shared.avalartAnzeigetextMap[1]')],
  [Bürgschaftsart.ANVORAUSZAHLUNG, i18n.global.t('shared.avalartAnzeigetextMap[2]')],
  [Bürgschaftsart.AUSFUEHRUNG, i18n.global.t('shared.avalartAnzeigetextMap[3]')],

  // Neue Werte
  [Bürgschaftsart.ALLGEMEIN, i18n.global.t('shared.avalartAnzeigetextMap[36]')],
  [Bürgschaftsart.ANZAHLUNG, i18n.global.t('shared.avalartAnzeigetextMap[4]')],
  [Bürgschaftsart.ARBEITSZEITKONTEN, i18n.global.t('shared.avalartAnzeigetextMap[5]')],
  [Bürgschaftsart.AUSLAND, i18n.global.t('shared.avalartAnzeigetextMap[6]')],
  [Bürgschaftsart.AUSSCHÜTTUNG, i18n.global.t('shared.avalartAnzeigetextMap[7]')],
  [Bürgschaftsart.BAUHANDWERKERSICHERUNG, i18n.global.t('shared.avalartAnzeigetextMap[8]')],
  [Bürgschaftsart.BIETUNG, i18n.global.t('shared.avalartAnzeigetextMap[9]')],
  [Bürgschaftsart.BIMSCHG, i18n.global.t('shared.avalartAnzeigetextMap[10]')],
  [Bürgschaftsart.BRANNTWEIN, i18n.global.t('shared.avalartAnzeigetextMap[11]')],
  [Bürgschaftsart.DECKUNGSHAFTUNGSRUECKLASS, i18n.global.t('shared.avalartAnzeigetextMap[12]')],
  [Bürgschaftsart.DECKUNGSRUECKLASS, i18n.global.t('shared.avalartAnzeigetextMap[13]')],
  [Bürgschaftsart.ERSCHLIESSUNG, i18n.global.t('shared.avalartAnzeigetextMap[14]')],
  [Bürgschaftsart.FERTIGSTELLUNG, i18n.global.t('shared.avalartAnzeigetextMap[15]')],
  [Bürgschaftsart.FERTIGSTELLUNGGARANTIE, i18n.global.t('shared.avalartAnzeigetextMap[16]')],
  [Bürgschaftsart.FRACHT, i18n.global.t('shared.avalartAnzeigetextMap[17]')],
  [Bürgschaftsart.GARANTIE, i18n.global.t('shared.avalartAnzeigetextMap[18]')],
  [Bürgschaftsart.GEWAEHRLEISTUNG, i18n.global.t('shared.avalartAnzeigetextMap[19]')],
  [Bürgschaftsart.HAFTUNG, i18n.global.t('shared.avalartAnzeigetextMap[20]')],
  [Bürgschaftsart.IATA, i18n.global.t('shared.avalartAnzeigetextMap[21]')],
  [Bürgschaftsart.LIEFERANTEN, i18n.global.t('shared.avalartAnzeigetextMap[22]')],
  [Bürgschaftsart.MABV, i18n.global.t('shared.avalartAnzeigetextMap[23]')],
  [Bürgschaftsart.MIET, i18n.global.t('shared.avalartAnzeigetextMap[24]')],
  [Bürgschaftsart.MINERALOEL, i18n.global.t('shared.avalartAnzeigetextMap[25]')],
  [Bürgschaftsart.PACHT, i18n.global.t('shared.avalartAnzeigetextMap[26]')],
  [Bürgschaftsart.PROZESS, i18n.global.t('shared.avalartAnzeigetextMap[27]')],
  [Bürgschaftsart.REKULTIVIERUNG, i18n.global.t('shared.avalartAnzeigetextMap[28]')],
  [Bürgschaftsart.RUECK, i18n.global.t('shared.avalartAnzeigetextMap[29]')],
  [Bürgschaftsart.SONSTIGE, i18n.global.t('shared.avalartAnzeigetextMap[30]')],
  [Bürgschaftsart.SONSTIGEGARANTIE, i18n.global.t('shared.avalartAnzeigetextMap[31]')],
  [Bürgschaftsart.TANKKARTEN, i18n.global.t('shared.avalartAnzeigetextMap[32]')],
  [Bürgschaftsart.VERTRAGSERFUELLUNGGARANTIE, i18n.global.t('shared.avalartAnzeigetextMap[33]')],
  [Bürgschaftsart.ZAHLUNG, i18n.global.t('shared.avalartAnzeigetextMap[34]')],
  [Bürgschaftsart.ZOLL, i18n.global.t('shared.avalartAnzeigetextMap[35]')],
]);

/** Bildet die Bürgschaftsart auf einen Anzeigetext ab - ohne das Suffix "bürgschaft" */
export const BürgschaftsartAnzeigetextMapReverse: Map<string, Bürgschaftsart> = new Map([
  // Vorhandene Einträge
  ['Mängelansprüche', Bürgschaftsart.MAENGELANSPRUECHE],
  ['Vertragserfüllung', Bürgschaftsart.VERTRAGSERFUELLUNG],
  ['An- & Vorauszahlung', Bürgschaftsart.ANVORAUSZAHLUNG],
  ['Ausführung', Bürgschaftsart.AUSFUEHRUNG],

  // Neue Werte
  ['Anzahlungen', Bürgschaftsart.ANZAHLUNG],
  ['Arbeitszeitkonten', Bürgschaftsart.ARBEITSZEITKONTEN],
  ['Ausland', Bürgschaftsart.AUSLAND],
  ['Ausschüttungen', Bürgschaftsart.AUSSCHÜTTUNG],
  ['Bauhandwerkersicherung', Bürgschaftsart.BAUHANDWERKERSICHERUNG],
  ['Bietung', Bürgschaftsart.BIETUNG],
  ['BImSchG', Bürgschaftsart.BIMSCHG],
  ['Branntwein', Bürgschaftsart.BRANNTWEIN],
  ['Deckungs-/Haftungsrücklass', Bürgschaftsart.DECKUNGSHAFTUNGSRUECKLASS],
  ['Deckungsrücklass', Bürgschaftsart.DECKUNGSRUECKLASS],
  ['Erschließung', Bürgschaftsart.ERSCHLIESSUNG],
  ['Fertigstellungs-/Haftungsrücklass', Bürgschaftsart.FERTIGSTELLUNG],
  ['Fertigstellung', Bürgschaftsart.FERTIGSTELLUNGGARANTIE],
  ['Fracht', Bürgschaftsart.FRACHT],
  ['Garantie', Bürgschaftsart.GARANTIE],
  ['Gewährleistung', Bürgschaftsart.GEWAEHRLEISTUNG],
  ['Haftungsrücklass', Bürgschaftsart.HAFTUNG],
  ['IATA', Bürgschaftsart.IATA],
  ['Lieferanten', Bürgschaftsart.LIEFERANTEN],
  ['MaBV', Bürgschaftsart.MABV],
  ['Miete', Bürgschaftsart.MIET],
  ['Mineralöl', Bürgschaftsart.MINERALOEL],
  ['Pacht', Bürgschaftsart.PACHT],
  ['Prozess', Bürgschaftsart.PROZESS],
  ['Rekultivierung', Bürgschaftsart.REKULTIVIERUNG],
  ['Rück', Bürgschaftsart.RUECK],
  ['Sonstige', Bürgschaftsart.SONSTIGE],
  ['Sonstige Garantie', Bürgschaftsart.SONSTIGEGARANTIE],
  ['Tankkarten', Bürgschaftsart.TANKKARTEN],
  ['Vertragserfüllungsgarantie', Bürgschaftsart.VERTRAGSERFUELLUNGGARANTIE],
  ['Zahlung', Bürgschaftsart.ZAHLUNG],
  ['Zoll', Bürgschaftsart.ZOLL],
]);

/** Bildet die Bürgschaftsart auf einen Anzeigetext ab - ohne das Suffix "texte" */
export const BürgschaftsartAnzeigetextMapPlural: Map<Bürgschaftsart, string> = new Map([
  // Vorhandene Einträge
  [Bürgschaftsart.MAENGELANSPRUECHE, 'Mängelansprüche'],
  [Bürgschaftsart.VERTRAGSERFUELLUNG, 'Vertragserfüllungen'],
  [Bürgschaftsart.ANVORAUSZAHLUNG, 'An- & Vorauszahlungen'],
  [Bürgschaftsart.AUSFUEHRUNG, 'Ausführungen'],

  // Neue Werte
  [Bürgschaftsart.ALLGEMEIN, 'Allgemeine Bürgschaft'],
  [Bürgschaftsart.ANZAHLUNG, 'Anzahlungsbürgschaften'],
  [Bürgschaftsart.ARBEITSZEITKONTEN, 'Arbeitszeitkontenbürgschaften'],
  [Bürgschaftsart.AUSLAND, 'Auslandsbürgschaften'],
  [Bürgschaftsart.AUSSCHÜTTUNG, 'Ausschüttungsbürgschaften'],
  [Bürgschaftsart.BAUHANDWERKERSICHERUNG, 'Bauhandwerkersicherungsbürgschaften'],
  [Bürgschaftsart.BIETUNG, 'Bietungsbürgschaften'],
  [Bürgschaftsart.BIMSCHG, 'BImSchG-Bürgschaften'],
  [Bürgschaftsart.BRANNTWEIN, 'Branntweinbürgschaften'],
  [Bürgschaftsart.DECKUNGSHAFTUNGSRUECKLASS, 'Deckungs-/Haftungsrücklassbürgschaften'],
  [Bürgschaftsart.DECKUNGSRUECKLASS, 'Deckungsrücklassbürgschaften'],
  [Bürgschaftsart.ERSCHLIESSUNG, 'Erschließungsbürgschaften'],
  [Bürgschaftsart.FERTIGSTELLUNG, 'Fertigstellungs-/Haftungsrücklassbürgschaften'],
  [Bürgschaftsart.FERTIGSTELLUNGGARANTIE, 'Fertigstellungsgarantiebürgschaften'],
  [Bürgschaftsart.FRACHT, 'Frachtbürgschaften'],
  [Bürgschaftsart.GARANTIE, 'Garantiebürgschaften'],
  [Bürgschaftsart.GEWAEHRLEISTUNG, 'Gewährleistungsgarantiebürgschaften'],
  [Bürgschaftsart.HAFTUNG, 'Haftungsrücklassbürgschaften'],
  [Bürgschaftsart.IATA, 'IATA-Bürgschaften'],
  [Bürgschaftsart.LIEFERANTEN, 'Lieferantenbürgschaften'],
  [Bürgschaftsart.MABV, 'MaBV-Bürgschaften'],
  [Bürgschaftsart.MIET, 'Mietbürgschaften'],
  [Bürgschaftsart.MINERALOEL, 'Mineralölbürgschaften'],
  [Bürgschaftsart.PACHT, 'Pachtbürgschaften'],
  [Bürgschaftsart.PROZESS, 'Prozessbürgschaften'],
  [Bürgschaftsart.REKULTIVIERUNG, 'Rekultivierungsbürgschaften'],
  [Bürgschaftsart.RUECK, 'Rückbürgschaften'],
  [Bürgschaftsart.SONSTIGE, 'Sonstige Bürgschaften'],
  [Bürgschaftsart.SONSTIGEGARANTIE, 'Sonstige Garantiebürgschaften'],
  [Bürgschaftsart.TANKKARTEN, 'Tankkartenbürgschaften'],
  [Bürgschaftsart.VERTRAGSERFUELLUNGGARANTIE, 'Vertragserfüllungsgarantiebürgschaften'],
  [Bürgschaftsart.ZAHLUNG, 'Zahlungsbürgschaften'],
  [Bürgschaftsart.ZOLL, 'Zollbürgschaften'],
]);

/** Bildet die Bürgschaftsart auf einen Anzeigetext ab - mit dem Suffix "texte" */
export const BürgschaftsartAnzeigetextTextartPlural: Map<Bürgschaftsart, string> = new Map([
  // Vorhandene Einträge
  [Bürgschaftsart.MAENGELANSPRUECHE, 'Mängelansprüchetexte'],
  [Bürgschaftsart.VERTRAGSERFUELLUNG, 'Vertragserfüllungstexte'],
  [Bürgschaftsart.ANVORAUSZAHLUNG, 'An- & Vorauszahlungstexte'],
  [Bürgschaftsart.AUSFUEHRUNG, 'Ausführungstexte'],

  // Neue Werte
  [Bürgschaftsart.ALLGEMEIN, 'Allgemeine Bürgschaften'],
  [Bürgschaftsart.ANZAHLUNG, 'Anzahlungstexte'],
  [Bürgschaftsart.ARBEITSZEITKONTEN, 'Arbeitszeitkontentexte'],
  [Bürgschaftsart.AUSLAND, 'Auslandstexte'],
  [Bürgschaftsart.AUSSCHÜTTUNG, 'Ausschüttungstexte'],
  [Bürgschaftsart.BAUHANDWERKERSICHERUNG, 'Bauhandwerkersicherungstexte'],
  [Bürgschaftsart.BIETUNG, 'Bietungstexte'],
  [Bürgschaftsart.BIMSCHG, 'BImSchG-Texte'],
  [Bürgschaftsart.BRANNTWEIN, 'Branntweintexte'],
  [Bürgschaftsart.DECKUNGSHAFTUNGSRUECKLASS, 'Deckungs-/Haftungsrücklasstexte'],
  [Bürgschaftsart.DECKUNGSRUECKLASS, 'Deckungsrücklasstexte'],
  [Bürgschaftsart.ERSCHLIESSUNG, 'Erschließungstexte'],
  [Bürgschaftsart.FERTIGSTELLUNG, 'Fertigstellungs-/Haftungsrücklasstexte'],
  [Bürgschaftsart.FERTIGSTELLUNGGARANTIE, 'Fertigstellungstexte'],
  [Bürgschaftsart.FRACHT, 'Frachttexte'],
  [Bürgschaftsart.GARANTIE, 'Garantietexte'],
  [Bürgschaftsart.GEWAEHRLEISTUNG, 'Gewährleistungstexte'],
  [Bürgschaftsart.HAFTUNG, 'Haftungsrücklasstexte'],
  [Bürgschaftsart.IATA, 'IATA-Texte'],
  [Bürgschaftsart.LIEFERANTEN, 'Lieferantentexte'],
  [Bürgschaftsart.MABV, 'MaBV-Texte'],
  [Bürgschaftsart.MIET, 'Miettexte'],
  [Bürgschaftsart.MINERALOEL, 'Mineralöltexte'],
  [Bürgschaftsart.PACHT, 'Pachttexte'],
  [Bürgschaftsart.PROZESS, 'Prozesstexte'],
  [Bürgschaftsart.REKULTIVIERUNG, 'Rekultivierungstexte'],
  [Bürgschaftsart.RUECK, 'Rücktexte'],
  [Bürgschaftsart.SONSTIGE, 'Sonstige Texte'],
  [Bürgschaftsart.SONSTIGEGARANTIE, 'Sonstige Garantietexte'],
  [Bürgschaftsart.TANKKARTEN, 'Tankkartentexte'],
  [Bürgschaftsart.VERTRAGSERFUELLUNGGARANTIE, 'Vertragserfüllungsgarantietexte'],
  [Bürgschaftsart.ZAHLUNG, 'Zahlungstexte'],
  [Bürgschaftsart.ZOLL, 'Zolltexte'],
]);

/** Bildet die Bürgschaftsart auf einen Anzeigetext ab - mit dem Suffix "text" */
export const BürgschaftsartAnzeigetextTextartSingular: Map<Bürgschaftsart, string> = new Map([
  // Vorhandene Einträge
  [Bürgschaftsart.MAENGELANSPRUECHE, 'Mängelansprüchetext'],
  [Bürgschaftsart.VERTRAGSERFUELLUNG, 'Vertragserfüllungstext'],
  [Bürgschaftsart.ANVORAUSZAHLUNG, 'An- & Vorauszahlungstext'],
  [Bürgschaftsart.AUSFUEHRUNG, 'Ausführungstext'],

  // Neue Werte
  [Bürgschaftsart.ANZAHLUNG, 'Anzahlungstext'],
  [Bürgschaftsart.ARBEITSZEITKONTEN, 'Arbeitszeitkontentext'],
  [Bürgschaftsart.AUSLAND, 'Auslandstext'],
  [Bürgschaftsart.AUSSCHÜTTUNG, 'Ausschüttungstext'],
  [Bürgschaftsart.BAUHANDWERKERSICHERUNG, 'Bauhandwerkersicherungstext'],
  [Bürgschaftsart.BIETUNG, 'Bietungstext'],
  [Bürgschaftsart.BIMSCHG, 'BImSchG-Text'],
  [Bürgschaftsart.BRANNTWEIN, 'Branntweintext'],
  [Bürgschaftsart.DECKUNGSHAFTUNGSRUECKLASS, 'Deckungs-/Haftungsrücklasstext'],
  [Bürgschaftsart.DECKUNGSRUECKLASS, 'Deckungsrücklasstext'],
  [Bürgschaftsart.ERSCHLIESSUNG, 'Erschließungstext'],
  [Bürgschaftsart.FERTIGSTELLUNG, 'Fertigstellungs-/Haftungsrücklasstext'],
  [Bürgschaftsart.FERTIGSTELLUNGGARANTIE, 'Fertigstellungstext'],
  [Bürgschaftsart.FRACHT, 'Frachttext'],
  [Bürgschaftsart.GARANTIE, 'Garantietext'],
  [Bürgschaftsart.GEWAEHRLEISTUNG, 'Gewährleistungstext'],
  [Bürgschaftsart.HAFTUNG, 'Haftungsrücklasstext'],
  [Bürgschaftsart.IATA, 'IATA-Text'],
  [Bürgschaftsart.LIEFERANTEN, 'Lieferantentext'],
  [Bürgschaftsart.MABV, 'MaBV-Text'],
  [Bürgschaftsart.MIET, 'Miettext'],
  [Bürgschaftsart.MINERALOEL, 'Mineralöltext'],
  [Bürgschaftsart.PACHT, 'Pachttext'],
  [Bürgschaftsart.PROZESS, 'Prozesstext'],
  [Bürgschaftsart.REKULTIVIERUNG, 'Rekultivierungstext'],
  [Bürgschaftsart.RUECK, 'Rücktext'],
  [Bürgschaftsart.SONSTIGE, 'Sonstiger Text'],
  [Bürgschaftsart.SONSTIGEGARANTIE, 'Sonstiger Garantietext'],
  [Bürgschaftsart.TANKKARTEN, 'Tankkartentext'],
  [Bürgschaftsart.VERTRAGSERFUELLUNGGARANTIE, 'Vertragserfüllungsgarantietext'],
  [Bürgschaftsart.ZAHLUNG, 'Zahlungstext'],
  [Bürgschaftsart.ZOLL, 'Zolltext'],
]);

/** Bildet die Bürgschaftsart auf einen Anzeigetext ab - mit dem Suffix "vorlage" */
export const BürgschaftsartAnzeigetextMapVorlage: Map<Bürgschaftsart, string> = new Map([
  [Bürgschaftsart.MAENGELANSPRUECHE, 'Mängelansprüchevorlage'],
  [Bürgschaftsart.VERTRAGSERFUELLUNG, 'Vertragserfüllungsvorlage'],
  [Bürgschaftsart.ANVORAUSZAHLUNG, 'An- & Vorauszahlungsvorlage'],
  [Bürgschaftsart.AUSFUEHRUNG, 'Ausführungsvorlage'],

  // Weitere Bürgschaftsarten hinzufügen
  [Bürgschaftsart.ANZAHLUNG, 'Anzahlungsvorlage'],
  [Bürgschaftsart.ARBEITSZEITKONTEN, 'Arbeitszeitkontenvorlage'],
  [Bürgschaftsart.AUSLAND, 'Auslandsvorlage'],
  [Bürgschaftsart.AUSSCHÜTTUNG, 'Ausschüttungsvorlage'],
  [Bürgschaftsart.BAUHANDWERKERSICHERUNG, 'Bauhandwerkersicherungsvorlage'],
  [Bürgschaftsart.BIETUNG, 'Bietungsvorlage'],
  [Bürgschaftsart.BIMSCHG, 'BImSchG-Vorlage'],
  [Bürgschaftsart.BRANNTWEIN, 'Branntweinvorlage'],
  [Bürgschaftsart.DECKUNGSHAFTUNGSRUECKLASS, 'Deckungs-/Haftungsrücklassvorlage'],
  [Bürgschaftsart.DECKUNGSRUECKLASS, 'Deckungsrücklassvorlage'],
  [Bürgschaftsart.ERSCHLIESSUNG, 'Erschließungsvorlage'],
  [Bürgschaftsart.FERTIGSTELLUNG, 'Fertigstellungs-/Haftungsrücklassvorlage'],
  [Bürgschaftsart.FERTIGSTELLUNGGARANTIE, 'Fertigstellungsvorlage'],
  [Bürgschaftsart.FRACHT, 'Frachtvorlage'],
  [Bürgschaftsart.GARANTIE, 'Garantievorlage'],
  [Bürgschaftsart.GEWAEHRLEISTUNG, 'Gewährleistungsvorlage'],
  [Bürgschaftsart.HAFTUNG, 'Haftungsrücklassvorlage'],
  [Bürgschaftsart.IATA, 'IATA-Vorlage'],
  [Bürgschaftsart.LIEFERANTEN, 'Lieferantenvorlage'],
  [Bürgschaftsart.MABV, 'MaBV-Vorlage'],
  [Bürgschaftsart.MIET, 'Mietvorlage'],
  [Bürgschaftsart.MINERALOEL, 'Mineralölvorlage'],
  [Bürgschaftsart.PACHT, 'Pachtvorlage'],
  [Bürgschaftsart.PROZESS, 'Prozessvorlage'],
  [Bürgschaftsart.REKULTIVIERUNG, 'Rekultivierungsvorlage'],
  [Bürgschaftsart.RUECK, 'Rückvorlage'],
  [Bürgschaftsart.SONSTIGE, 'Sonstige Vorlage'],
  [Bürgschaftsart.SONSTIGEGARANTIE, 'Sonstige Garantievorlage'],
  [Bürgschaftsart.TANKKARTEN, 'Tankkartenvorlage'],
  [Bürgschaftsart.VERTRAGSERFUELLUNGGARANTIE, 'Vertragserfüllungsgarantievorlage'],
  [Bürgschaftsart.ZAHLUNG, 'Zahlungsvorlage'],
  [Bürgschaftsart.ZOLL, 'Zollvorlage'],
]);

export const BürgschaftsartIconPfad: Map<Bürgschaftsart, string> = new Map([
  [Bürgschaftsart.MAENGELANSPRUECHE, '/static/assets/img/buergschaftstexte/badge-mab.svg'],
  [Bürgschaftsart.VERTRAGSERFUELLUNG, '/static/assets/img/buergschaftstexte/badge-veb.svg'],
  [Bürgschaftsart.ANVORAUSZAHLUNG, '/static/assets/img/buergschaftstexte/badge-avb.svg'],
  [Bürgschaftsart.AUSFUEHRUNG, '/static/assets/img/buergschaftstexte/badge-aub.svg'],
]);

export const BürgschaftsartBezeichnungKürzel: Map<Bürgschaftsart, string> = new Map([
  // Vorhandene Einträge
  [Bürgschaftsart.MAENGELANSPRUECHE, 'MGL'],
  [Bürgschaftsart.VERTRAGSERFUELLUNG, 'VE'],
  [Bürgschaftsart.ANVORAUSZAHLUNG, 'ANVOR'],
  [Bürgschaftsart.AUSFUEHRUNG, 'AUS'],
  // Neue Werte
  [Bürgschaftsart.ANZAHLUNG, 'ANZ'],
  [Bürgschaftsart.ARBEITSZEITKONTEN, 'AZK'],
  [Bürgschaftsart.AUSLAND, 'AUL'],
  [Bürgschaftsart.AUSSCHÜTTUNG, 'ASB'],
  [Bürgschaftsart.BAUHANDWERKERSICHERUNG, 'BHS'],
  [Bürgschaftsart.BIETUNG, 'BIE'],
  [Bürgschaftsart.BIMSCHG, 'BIM'],
  [Bürgschaftsart.BRANNTWEIN, 'BRA'],
  [Bürgschaftsart.DECKUNGSHAFTUNGSRUECKLASS, 'DHG'],
  [Bürgschaftsart.DECKUNGSRUECKLASS, 'DEC'],
  [Bürgschaftsart.ERSCHLIESSUNG, 'ERS'],
  [Bürgschaftsart.FERTIGSTELLUNG, 'FHG'],
  [Bürgschaftsart.FERTIGSTELLUNGGARANTIE, 'FER'],
  [Bürgschaftsart.FRACHT, 'FRA'],
  [Bürgschaftsart.GARANTIE, 'GAR'],
  [Bürgschaftsart.GEWAEHRLEISTUNG, 'GWG'],
  [Bürgschaftsart.HAFTUNG, 'HAF'],
  [Bürgschaftsart.IATA, 'IAT'],
  [Bürgschaftsart.LIEFERANTEN, 'LIE'],
  [Bürgschaftsart.MABV, 'MVB'],
  [Bürgschaftsart.MIET, 'MIE'],
  [Bürgschaftsart.MINERALOEL, 'MIN'],
  [Bürgschaftsart.PACHT, 'PAC'],
  [Bürgschaftsart.PROZESS, 'PRO'],
  [Bürgschaftsart.REKULTIVIERUNG, 'REK'],
  [Bürgschaftsart.RUECK, 'RUE'],
  [Bürgschaftsart.SONSTIGE, 'SON'],
  [Bürgschaftsart.SONSTIGEGARANTIE, 'SOG'],
  [Bürgschaftsart.TANKKARTEN, 'TAN'],
  [Bürgschaftsart.VERTRAGSERFUELLUNGGARANTIE, 'VEG'],
  [Bürgschaftsart.ZAHLUNG, 'ZAH'],
  [Bürgschaftsart.ZOLL, 'ZOL'],
]);

export const BürgschaftsartAuftragBezeichnungKürzel: Map<Bürgschaftsart, string> = new Map([
  [Bürgschaftsart.MAENGELANSPRUECHE, 'MGL'],
  [Bürgschaftsart.VERTRAGSERFUELLUNG, 'VE'],
  [Bürgschaftsart.ANVORAUSZAHLUNG, 'VOR'],
  [Bürgschaftsart.AUSFUEHRUNG, 'AUS'],

  // Neue Werte
  [Bürgschaftsart.ANZAHLUNG, 'ANZ'],
  [Bürgschaftsart.ARBEITSZEITKONTEN, 'AZK'],
  [Bürgschaftsart.AUSLAND, 'AUL'],
  [Bürgschaftsart.AUSSCHÜTTUNG, 'ASB'],
  [Bürgschaftsart.BAUHANDWERKERSICHERUNG, 'BHS'],
  [Bürgschaftsart.BIETUNG, 'BIE'],
  [Bürgschaftsart.BIMSCHG, 'BIM'],
  [Bürgschaftsart.BRANNTWEIN, 'BRA'],
  [Bürgschaftsart.DECKUNGSHAFTUNGSRUECKLASS, 'DHG'],
  [Bürgschaftsart.DECKUNGSRUECKLASS, 'DEC'],
  [Bürgschaftsart.ERSCHLIESSUNG, 'ERS'],
  [Bürgschaftsart.FERTIGSTELLUNG, 'FHG'],
  [Bürgschaftsart.FERTIGSTELLUNGGARANTIE, 'FER'],
  [Bürgschaftsart.FRACHT, 'FRA'],
  [Bürgschaftsart.GARANTIE, 'GAR'],
  [Bürgschaftsart.GEWAEHRLEISTUNG, 'GWG'],
  [Bürgschaftsart.HAFTUNG, 'HAF'],
  [Bürgschaftsart.IATA, 'IAT'],
  [Bürgschaftsart.LIEFERANTEN, 'LIE'],
  [Bürgschaftsart.MABV, 'MVB'],
  [Bürgschaftsart.MIET, 'MIE'],
  [Bürgschaftsart.MINERALOEL, 'MIN'],
  [Bürgschaftsart.PACHT, 'PAC'],
  [Bürgschaftsart.PROZESS, 'PRO'],
  [Bürgschaftsart.REKULTIVIERUNG, 'REK'],
  [Bürgschaftsart.RUECK, 'RUE'],
  [Bürgschaftsart.SONSTIGE, 'SON'],
  [Bürgschaftsart.SONSTIGEGARANTIE, 'SOG'],
  [Bürgschaftsart.TANKKARTEN, 'TAN'],
  [Bürgschaftsart.VERTRAGSERFUELLUNGGARANTIE, 'VEG'],
  [Bürgschaftsart.ZAHLUNG, 'ZAH'],
  [Bürgschaftsart.ZOLL, 'ZOL'],
]);

/** Zusatzklausel an einer Bürgschaft */
export interface Zusatzklausel {
  erstesanfordern: boolean;
  hinterlegung: boolean;
  sicherheitseinbehalt: boolean;
}

/** Daten zur Bürgschaftskorrektur, die der Auftraggeber anfordern kann, wenn er mit der Bürgschaft nicht einverstanden ist */
export interface Bürgschaftskorrektur {
  änderungsgrund: Änderungsgrund[];
  änderungshinweis: string;
  erstelltAm?: string;
  erstelltVon?: string;
  erstelltVonName?: string;
  bürgschaftVersion: number;
}

export interface BearbeitenDaten {
  voraussichtlichesAblaufdatum: string;
  bürgschaftsstatus: Bürgschaftsstatus;
  internerVermerk: string;
  loeschen: string;
  buergschaftBearbeitungArt: BuergschaftBearbeitungArt;
  version: number;
}

export interface StatusBearbeitenDaten {
  status: Bürgschaftsstatus;
  version: number;
}

/** Angabe zur Teilenthaftung, die ein Auftraggeber beantragen kann (Bürgschaftsbetrag wird reduziert) */
export interface TeilenthaftungHistorie {
  bürgschaftsbetrag: string; // Hier wäre ein spezieller Datentyp gut
  minderungsbetrag: string; // Hier wäre ein spezieller Datentyp gut
}

/** Angaben zu geteilten Bürgschaften */
export interface BürgschaftTeilenInformationen {
  nachname: string;
  email: string;
  telefon: string;
  hinweistext: string;
  vorname: string;
}

export interface Kontaktdaten {
  email?: string;
  vorname?: string;
  nachname?: string;
  name?: string;
  telefonnummer?: string;
  postadresse?: Postadresse;
}

export interface MitteilungInformationen {
  nachricht: string;
  absender?: Kontaktdaten;
}

export interface AendernInformationen {
  aenderungsTyp: AenderungsTyp;
  neuerWert?: unknown;
}

export interface AbtretungInformationen {
  kommentar?: string;
  zessionarName: string;
  zedentName: string;
  zessionarId: string | null;
  zessionarAdresse: Postadresse;
  zessionarAnsprechpartner: Ansprechpartner;
  zedentAdresse: Postadresse;
  zedentAnsprechpartner: Ansprechpartner;
  status: string;
  geandertVonName: string;
  aufruferIstZedent: boolean;
  ablehnungInformationen: AblehnungInformationen;

  abtretungsDatum: Date;
}

export enum Rueckforderungsstatus {
  OFFEN = 'OFFEN',
  ANGENOMMEN = 'ANGENOMMEN',
  ABGELEHNT = 'ABGELEHNT',
  FEHLER = 'FEHLER',
}

export interface RueckforderungInformationen {
  auftragnehmerName: string;
  auftraggeberName: string;
  ablehnungGrund: string;
  ansprechpartnerAuftraggeber: Ansprechpartner;
  rueckforderungsstatus: Rueckforderungsstatus;
}

export interface AblehnungInformationen {
  nachricht: string;
  kontaktInformationen: Kontaktdaten;
}

/** Eine Bürgschaft hat eine Änderungshistorie. Dies ist ein Eintrag aus der Historie zu einer konkreten Änderung bzw. einem Zeitpunkt in der Historie */
export interface BürgschaftHistorie {
  erstelltAm: Date;
  erstelltVon: string;
  geändertAm: Date;
  geändertVon: string;
  bürgschaftsstatus: Bürgschaftsstatus;
  // Heißt das Feld Manchmal bürgschaft S IdBürge?
  // Behalte es für ggf. Kompatibilität, scheint aber nicht verwendet zu werden.
  bürgschaftsIdBürge: string;
  bürgschaftIdBürge: string;
  bürge: Bürge;
  vorherigerBürgschaftsstatus: Bürgschaftsstatus;
  korrektur: Bürgschaftskorrektur;
  teilenthaftungBetrag: TeilenthaftungHistorie;
  bürgschaftTeilenInformationen: BürgschaftTeilenInformationen | null;
  mitteilungInformationen: MitteilungInformationen | null;
  herkunftVereinfacht: HerkunftVereinfacht;
  aendernInformationen: AendernInformationen | null;
  abtretungInformationen: AbtretungInformationen | null;
  rueckforderungInformationen: RueckforderungInformationen | null;
  pruefentscheidungInformationen: PruefentscheidungInformationen | null;
  auftraggeberId: string | null;
  auftraggeberName: string | null;
  auftragnehmerId: string | null;
  auftragnehmerName: string | null;
}

export interface BuergschaftAendernHistorie {
  geaendertAm: Date;
  geaendertVon: string;
  wert: any;
  initialerWert: boolean;
}

/** Eine Bürgschaft u.U. eine Liste von Kommentaren. Dies ist ein einzelnes Kommentar-Objekt */
export interface Bürgschaftskommentar extends KommentarObject {
  bürgschaftId: number;
}

export interface BürgschaftskommentarUpload {
  kommentar: string;
}

export function createEmptyBuergschaftskommentarUpload(text: string): BürgschaftskommentarUpload {
  return {
    kommentar: text,
  };
}

/** Eine Bürgschaft kann immer nur genau eine Erinnerung haben. Dies ist ein einzelnes Bürgschaftserinnerung-Objekt */
export interface Bürgschaftserinnerung extends ErinnerungObject {
  bürgschaftId?: number;
}

export function createEmptyBuergschaftserinnerung(datum: string | Date): Bürgschaftserinnerung {
  return <Bürgschaftserinnerung>{
    erinnerungsdatum: datum,
  };
}

/** Statusübergänge einer Bürgschaft, für die wir in der Historie Einträge anzeigen */
export const StatusWhitelist: Bürgschaftsstatus[] = [
  Bürgschaftsstatus.ENT,
  Bürgschaftsstatus.UP,
  Bürgschaftsstatus.AKT,
  Bürgschaftsstatus.IK,
  Bürgschaftsstatus.ANB,
  Bürgschaftsstatus.ZGBB,
  Bürgschaftsstatus.ZGB,
  Bürgschaftsstatus.ABB,
  Bürgschaftsstatus.TEB,
];

/**
 * NUR FÜR die Domäne AG:
 * Nutzer in der Auftraggeber-Domäne können bei Bürgschaften entweder Auftraggeber oder
 * Auftragnehmer sein (Hybridkunden)
 *
 * Das Konstrukt der Aufruferrolle wird ausschließlich zur Unterscheidung der Rolle verwendet, die
 * ein Bauuntnernehmen einnehmen kann : entweder Schuldner (Auftragnehmer) oder
 * Gläubiger (Auftraggeber)
 *
 * NICHT: die Aufruferrolle für die Abgrenzung von Bürgen und Trustlog nutzen
 */
export enum AufruferRolle {
  AUFTRAGGEBER = 'AUFTRAGGEBER',
  AUFTRAGNEHMER = 'AUFTRAGNEHMER',
  UNBEKANNTER = 'UNBEKANNTER',
}

// TODO Das ganze muss noch tiefergehend integriert / ausgetauscht und korrigiert werden.
// Dies war erst einmal ein erster Aufschlag / Versuch hier die richtigen Bezeichnungen / Namen zu finden.
export enum Beteiligte {
  AUFTRAGGEBER = 'AUFTRAGGEBER',
  AUFTRAGNEHMER = 'AUFTRAGNEHMER',
  UNBEKANNTER = 'UNBEKANNTER',
  ZEDENT = 'ZEDENT',
  ZESSIONAR = 'ZESSIONAR',
}

export enum AenderungsTyp {
  VORAUSSICHTLICHES_ABLAUFDATUM = 'VORAUSSICHTLICHES_ABLAUFDATUM',
  INTERNER_VERMERK = 'INTERNER_VERMERK',
  BUERGSCHAFT_STATUS = 'BUERGSCHAFT_STATUS',
  AUFTRAGNEHMER_ID = 'AUFTRAGNEHMER_ID',
  AUFTRAGGEBER_ID = 'AUFTRAGGEBER_ID',
}

export const AenderungsTypDatentyp: Map<AenderungsTyp, string> = new Map([
  [AenderungsTyp.VORAUSSICHTLICHES_ABLAUFDATUM, 'Datum'],
  [AenderungsTyp.INTERNER_VERMERK, 'Zeichenkette'],
]);

/** Änderungsgrund für eine Korrekturanfrage */
export enum Änderungsgrund {
  /** Bürgschaft ablehnen */
  ABGELEHNT = 'ABGELEHNT',

  /** Fehlerhaftes Konzernunternehmen */
  KORR_UNT = 'KORR_UNT',

  /** Fehlerhafter Bürgschaftstext */
  KORR_TXT = 'KORR_TXT',

  /** Fehlerhafter Bürgschaftshöchstbetrag */
  KORR_BETR = 'KORR_BETR',

  /** Fehlerhafte oder unvollständige Art der Arbeit */
  KORR_GEW = 'KORR_GEW',

  /** Fehlerhafter Ort der Arbeit */
  KORR_BAUV = 'KORR_BAUV',

  /** Sonstige Änderungswünsche */
  KORR_SON = 'KORR_SON',
}

/** Klartexte für die Art der Ablehnung, falls ein Auftraggeber die Bürgschaft abgelehnt oder in Korrektur gegeben hat */
export const AenderungsgrundAnzeigetextShared: Map<Änderungsgrund, string> = new Map([
  [
    Änderungsgrund.ABGELEHNT,
    i18n.global.t(`shared.avale.common.ablehnenAenderungsGruende.grund.abgelehnt`),
  ],
  [
    Änderungsgrund.KORR_UNT,
    i18n.global.t(`shared.avale.common.ablehnenAenderungsGruende.grund.beguenstigter`),
  ],
  [
    Änderungsgrund.KORR_TXT,
    i18n.global.t(`shared.avale.common.ablehnenAenderungsGruende.grund.text`),
  ],
  [
    Änderungsgrund.KORR_BETR,
    i18n.global.t(`shared.avale.common.ablehnenAenderungsGruende.grund.betrag`),
  ],
  [
    Änderungsgrund.KORR_GEW,
    i18n.global.t(`shared.avale.common.ablehnenAenderungsGruende.grund.artDerArbeit`),
  ],
  [
    Änderungsgrund.KORR_BAUV,
    i18n.global.t(`shared.avale.common.ablehnenAenderungsGruende.grund.ortDerArbeit`),
  ],
  [
    Änderungsgrund.KORR_SON,
    i18n.global.t(`shared.avale.common.ablehnenAenderungsGruende.grund.sonstige`),
  ],
]);

/** Variante i18n für Bürgen-Domäne  */
export const AenderungsgrundAnzeigetextBuerge: Map<Änderungsgrund, string> = new Map([
  [
    Änderungsgrund.ABGELEHNT,
    i18n.global.t(`avalgeber.avale.common.ablehnenAenderungsGruende.grund.abgelehnt`),
  ],
  [
    Änderungsgrund.KORR_UNT,
    i18n.global.t(`avalgeber.avale.common.ablehnenAenderungsGruende.grund.beguenstigter`),
  ],
  [
    Änderungsgrund.KORR_TXT,
    i18n.global.t(`avalgeber.avale.common.ablehnenAenderungsGruende.grund.text`),
  ],
  [
    Änderungsgrund.KORR_BETR,
    i18n.global.t(`avalgeber.avale.common.ablehnenAenderungsGruende.grund.betrag`),
  ],
  [
    Änderungsgrund.KORR_GEW,
    i18n.global.t(`avalgeber.avale.common.ablehnenAenderungsGruende.grund.artDerArbeit`),
  ],
  [
    Änderungsgrund.KORR_BAUV,
    i18n.global.t(`avalgeber.avale.common.ablehnenAenderungsGruende.grund.ortDerArbeit`),
  ],
  [
    Änderungsgrund.KORR_SON,
    i18n.global.t(`avalgeber.avale.common.ablehnenAenderungsGruende.grund.sonstige`),
  ],
]);

/** Daten zur Enthaftung (Rückgabe) einer Bürgschaft */
export interface Enthaftung {
  stornodatum: string;
}

/** Daten zur Teilenthaftung (Reduzierung des Bürgschaftsbetrags) einer Bürgschaft */
export interface Teilenthaftung {
  minderungsbetrag: string; // siehe auch verminderterBürgschaftsbetrag für bürgschaftsbetrag - minderungsbetrag
  reduzierterBürgschaftsbetrag: string;
  wirksamkeitsdatum: string;
  bürgschaftVersion: number;
}

export interface Textverifizierung {
  verifizierungTextart: boolean;
  verifizierungalterText: boolean;
  verifizierungBefristung: boolean;
  verifizierungBaurecht: boolean;
}

export interface AltbürgschaftValidierungsErgebnis {
  korrekt: number;
  falsch: number;
}

export interface BürgschaftsAuftrag {
  auftragId?: number;
  auftragsId?: number;
  eingangszeitpunkt?: string;
}

/**
 * Lieferkanäle
 */
export enum BuergschaftsLieferkanal {
  POST = 'POST', // Druckstraße des Bürgen
  PDF = 'PDF', // PDF an Auftragnehmer
  TRUSTLOG = 'TRUSTLOG', // Verwaltet in Trustlog Plattform
  GASTLOG = 'GASTLOG', // Bereitstellung über einen Gastzugang
}

export function isAvalArtAktivFuerBuerge(avalArt: Bürgschaftsart, buerge: string): boolean {
  const erlaubteBuergenEnv: string | undefined = import.meta.env.VITE_AVALART_BUERGEN;

  const erlaubteBuergen: string[] = erlaubteBuergenEnv
    ? erlaubteBuergenEnv.split(',').map((b) => b.trim())
    : ['UI3221', 'EQ6215', 'JD9531'];

  return (
    erlaubteBuergen.includes(buerge) &&
    [Bürgschaftsart.ALLGEMEIN, Bürgschaftsart.MIET].includes(avalArt)
  );
}
