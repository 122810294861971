export enum AuftraggeberRoutes {
  // Aufgaben
  AG_AUFGABEN_LISTE = 'aufgabenListeAuftraggeber',
  // Bürgschaft Detailansicht
  AG_BUERGSCHAFT_DETAIL = 'buergschaftDetailsAuftraggeber',

  // Inanspruchnahme Workflow
  AG_INANSPRUCHNAHME_BASE = 'auftraggeber-inanspruchnahme-base',
  AG_INANSPRUCHNAHME_ANGABEN = 'auftraggeber-inanspruchnahme-angaben',
  AG_INANSPRUCHNAHME_ANSPRECHPARTNER = 'auftraggeber-inanspruchnahme-ansprechpartner',
  AG_INANSPRUCHNAHME_PRUEFEN = 'auftraggeber-inanspruchnahme-pruefen',

  // Enthaftung angefordert
  AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE = 'auftraggeber-rueckforderung-pruefen-aufgabe-base',
  AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE_PRUEFEN = 'auftraggeber-rueckforderung-pruefen-aufgabe-pruefen',
  AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE_ENTSCHEIDUNG = 'auftraggeber-rueckforderung-pruefen-aufgabe-entscheidung',
  AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE_BESTAETIGEN = 'auftraggeber-rueckforderung-pruefen-aufgabe-bestaetigen',
  AG_BUERGSCHAFT_ABGETRETEN_DETAIL = 'buergschaftAbgetretenDetailsAuftraggeber',
  // Abgetretene Bürgschaft Detailansicht
  // Bürgschaft-Abtreten
  AG_BUERGSCHAFT_ABTRETEN = 'auftraggeber-buergschaft-abtreten-base',
  AG_BUERGSCHAFT_ABTRETEN_EMPFAENGER = 'auftraggeber-buergschaft-abtreten-empfaenger',
  AG_BUERGSCHAFT_ABTRETEN_ANSPRECHPARTNER = 'auftraggeber-buergschaft-abtreten-ansprechpartner',
  AG_BUERGSCHAFT_ABTRETEN_PRUEFEN = 'auftraggeber-buergschaft-abtreten-pruefen',
  AG_BUERGSCHAFT_ABTRETEN_BESTAETIGEN = 'auftraggeber-buergschaft-abtreten-bestaetigen',
  // Abtretung prüfen
  AG_ABTRETUNG_PRUEFEN_AUFGABE = 'auftraggeber-abtretung-pruefen-aufgabe-base',
  AG_ABTRETUNG_PRUEFEN_AUFGABE_PRUEFEN = 'auftraggeber-abtretung-pruefen-aufgabe-pruefen',
  AG_ABTRETUNG_PRUEFEN_AUFGABE_ENTSCHEIDUNG = 'auftraggeber-abtretung-pruefen-aufgabe-entscheidung',
  AG_ABTRETUNG_PRUEFEN_AUFGABE_BESTAETIGEN = 'auftraggeber-abtretung-pruefen-aufgabe-bestaetigen',
  // Projekt Details Bearbeiten
  AG_PROJEKT_FORM_BEARBEITEN = 'auftraggeber-projekt-bearbeiten-base',
  AG_PROJEKT_FORM_ANLEGEN = 'auftraggeber-projekt-anlegen-base',
  AG_PROJEKT_FORM_DETAILS = 'auftraggeber-projekt-form-details',
  AG_PROJEKT_FORM_VERANTWORTLICHES_UNTERNEHMEN = 'auftraggeber-projekt-form-verantwortliches-unternehmen',
  AG_PROJEKT_FORM_SICHTBERECHTIGUNG = 'auftraggeber-projekt-form-sichtberechtigung',

  AG_VERTRAEGE_LISTE = 'verträgeListeAuftraggeber',
  AG_VERTRAG_ANLEGEN = 'auftraggeber_vertrag_anlegen_base',
  AG_VERTRAG_ANLEGEN_BUERGE = 'auftraggeber_vertrag_anlegen_buerge_auswaehlen',
  AG_VERTRAG_ANLEGEN_VERSICHERUNGSNEHMER = 'auftraggeber_vertrag_anlegen_versicherungsnehmer_auswaehlen',
  AG_VERTRAG_ANLEGEN_VERTRAGSDATEN = 'auftraggeber_vertrag_anlegen_vertragsdaten_eingeben',
  AG_VERTRAG_ANLEGEN_UNTERNEHMEN = 'auftraggeber_vertrag_anlegen_unternehmen_verknuepfen',
  AG_VERTRAG_ANLEGEN_ZUSAMMENFASSUNG = 'auftraggeber_vertrag_anlegen_zusammenfassung',
}
