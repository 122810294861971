import { HttpService } from '@/shared/api/services/HttpService';
import { buildUrlForServiceName } from '@/connection/url-retriever';
import conf from '@/shared/config/useConfig';
import type { Kommunikation, Gevo } from '@/shared/AvalKonfigurator/Kommunikation';

export class SharedAvalPartnerKonfiguratorService extends HttpService {
  protected avalPartnerKonfiguratorServiceBaseUrl = `${buildUrlForServiceName(
    conf.avalpartnerkonfiguratorName,
  )}/api/private/v1`;

  async holeBuergenKommunikation(gevo: Gevo, buergeId: string): Promise<Kommunikation> {
    return this.httpClient.get(
      `${this.avalPartnerKonfiguratorServiceBaseUrl}/buerge/${buergeId}/gevo/${gevo}`,
    );
  }

  async holeAuftraggeberKommunikation(gevo: Gevo, unternehmenId: string): Promise<Kommunikation> {
    return this.httpClient.get(
      `${this.avalPartnerKonfiguratorServiceBaseUrl}/auftraggeber/${unternehmenId}/gevo/${gevo}`,
    );
  }
}
