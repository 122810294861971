import type {
  AvalgeberAuftragWorkflowAuftragPruefenLocaleSchema,
  AvalgeberAvalWorkflowAvalZweitpruefenLocaleSchema,
} from '@/lang/types/types.ts';
import type { AvalKorrigieren } from '@/lang/types/avalgeber/avalKorrigieren.ts';

export const pruefenWorkflow: AvalgeberAuftragWorkflowAuftragPruefenLocaleSchema = {
  contentHeader: 'Auftrag prüfen',
  banner: {
    versandInfo: {
      header: 'Versandart berücksichtigen: Aval in Papierform',
      text: 'Da der Begünstigte nicht auf Trustlog registriert ist, ist eine digitale Ausstellung über Trustlog nicht möglich. Bitte stellen Sie das Aval in Papierform aus.',
      zusatzDirekt:
        'Bitte versenden Sie das Aval an den <strong>Begünstigten</strong>. Versandadresse gemäß Aval.',
      zusatzAbweichend: 'Bitte versenden Sie das Aval an folgende Adresse:',
    },
    versandWarnung: {
      header: 'Versandart berücksichtigen: Digitales Aval über Trustlog',
      text: 'Da der Begünstigte an Trustlog teilnimmt, wird das Aval digital über Trustlog ausgestellt.',
    },
  },
  steps: {
    details: {
      title: 'Auftragsdaten und Avaltext prüfen',
      beschreibung: 'Sie haben einen Avalauftrag mit folgenden Eckdaten erhalten:',
      pruefAufforderung:
        'Bitte prüfen Sie den unten stehenden Auftrag und wählen Sie auf der nächsten Seite aus, ob der Auftrag akzeptiert oder abgelehnt werden soll.',
      columnTitles: {
        vertragsnummer: 'Vertragsnummer',
        kreditnehmer: 'Kreditnehmer',
        ansprechpartner: 'Ansprechpartner',
      },
      downloadButton: 'Auftrag herunterladen',
    },

    entscheidung: {
      title: 'Auftrag akzeptieren?',
      beschreibung:
        'Wählen Sie aus, ob Sie den unten stehenden Avalauftrag akzeptieren oder ablehnen möchten.',
      fehlermeldung: 'Bitte wählen Sie eine Option aus.',

      radioOptions: {
        first: {
          imBuergenSystem: {
            title: 'Ja, Auftrag akzeptieren und Aval verknüpfen',
            subText:
              'Sie akzeptieren den Auftrag, ohne dass Trustlog am Ende dieses Prozesses ein Aval erstellt. Bitte stellen Sie das Aval in Ihrem System aus. Dieses Aval wird anschließend per Schnittstelle an Trustlog übertragen. Um die Verknüpfung zwischen dem Auftrag und dem Aval herzustellen, geben Sie im nächsten Schritt die Avalnummer des neuen Avals an.',
          },
          inTrustlog: {
            title: 'Ja, Auftrag akzeptieren',
            subTextMitTemplate: `Sie akzeptieren den Auftrag wie unten dargestellt. Sie können im nächsten Schritt die Avalnummer und das Ausstellungsdatum eintragen, um das Aval auszustellen.`,
            subTextOhneTemplate:
              'Sie akzeptieren den Auftrag wie unten dargestellt. Passen Sie bei Bedarf im nächsten Schritt die Avalnummer und das Ausstellungsdatum und laden Sie {buergschaftsdokumentPflichtText} das Avaldokument hoch.',
          },
          vierAugen: {
            title: 'Ja, Auftrag akzeptieren',
            subText:
              'Sie akzeptieren den Auftrag wie unten dargestellt. Sie können im nächsten Schritt die Avalnummer und das Ausstellungsdatum eintragen, um das Aval zur Zweitprüfung weiterzuleiten.',
          },
        },

        second: {
          title: 'Ja, Auftrag mit Anpassungen akzeptieren',
          subTextMitTemplate: `Sie akzeptieren den Auftrag wie unten
    dargestellt, nur mit einer oder mehreren Anpassungen. Sie können die Daten aus dem Auftrag im nächsten
    Schritt bearbeiten, sowie die Avalnummer und das Ausstellungsdatum eintragen.`,

          subTextOhneTemplate:
            'Sie akzeptieren den Auftrag wie unten dargestellt mit Anpassungen. Sie können die Daten aus dem Auftrag im nächsten Schritt bearbeiten, sowie die Avalnummer und das Ausstellungsdatum eintragen und {buergschaftsdokumentPflichtText} das Avaldokument hochladen.',
        },

        third: {
          title: 'Nein, Auftrag ablehnen',
          subText:
            'Sie lehnen den Auftrag ab und können den Ablehnungsgrund und einen Ansprechpartner angeben.',
        },
      },
    },

    bearbeiten: {
      title: 'Auftragsdaten bearbeiten',
      beschreibung:
        'In diesem Schritt können Sie die Daten aus dem Auftrag so bearbeiten, wie sie nachher auf dem Aval erscheinen sollen.',
      fehlermeldung:
        'Passen Sie mindestens einen Wert an oder wählen Sie im vorherigen Schritt die Option ‘Ja, Auftrag ohne Anpassungen akzeptieren’',

      form: {
        fehlermeldung: 'Passen Sie mindestens einen Wert an oder brechen Sie die Bearbeitung ab',
        placeholder: {
          leistung: 'Bitte beschreiben Sie die Leistung...',
          ortDerLeistung: 'Bitte beschreiben Sie den Ort der Leistung …',
        },
      },
    },

    ablehnen: {
      title: 'Auftrag ablehnen',
      beschreibung:
        'Sie lehnen den Auftrag ab. Geben Sie optional Kontaktdaten eines Ansprechpartners und einen Ablehnungsgrund ein.',
      form: {
        label: {
          ablehnungsgrund: 'Ablehnungsgrund',
        },
        placeholder: { ablehnungsgrund: 'Beschreiben Sie den Grund für die Ablehnung …' },
      },
    },

    akzeptieren: {
      ohneAnpassung: {
        title: 'Auftrag akzeptieren',
        subTextMitTemplate:
          'Ergänzen Sie die Avalnummer und das Ausstellungsdatum ein, um das Aval auszustellen.',
        subTextOhneTemplate:
          'Ergänzen Sie die Avalnummer und das Ausstellungsdatum ein und {getBeschreibungText}.',
      },
      mitAnpassung: {
        title: 'Auftrag mit Anpassungen akzeptieren',
        subTextMitTemplate:
          'Bitte bearbeiten Sie die anzupassenden Daten und tragen Sie die Avalnummer und das Ausstellungsdatum ein, um das Aval auszustellen.',
        subTextOhneTemplate:
          'Bitte bearbeiten Sie die anzupassenden Daten, tragen Sie die Avalnummer und das Ausstellungsdatum ein und {getBeschreibungText}.',
      },
      imBuergenSystem: {
        title: 'Auftrag akzeptieren und Aval verknüpfen',
        subText:
          'Ergänzen Sie die Avalnummer des Avals ein, das Sie in Ihrem System erstellt haben. Im Anschluss wird dieser Auftrag mit dem Aval technisch verknüpft.',
      },

      vierAugenOhneAnpassung: {
        title: 'Auftrag akzeptieren',
        subText:
          'Ergänzen Sie die Avalnummer und das Ausstellungsdatum, um das Aval zur Zweitprüfung weiterzuleiten.',
      },

      vierAugenMitAnpassung: {
        title: 'Auftrag mit Anpassungen akzeptieren',
        subText:
          'Bitte bearbeiten Sie die anzupassenden Daten und tragen Sie die Avalnummer und das Ausstellungsdatum ein, um das Aval zur Zweitprüfung weiterzuleiten.',
      },

      subTextZusatz: {
        kopieHochladen: 'laden Sie optional eine Kopie des Avaldokument hoch',
        avalDokumentHochladen: 'laden Sie das Avaldokument hoch',
      },

      buttons: {
        primary: {
          akzeptieren: 'Auftrag akzeptieren',
          ausstellen: 'Aval ausstellen',
          inPruefung: 'Erstprüfung abschließen',
        },
      },

      form: {
        label: {
          hinweisZweitpruefer: 'Hinweis an Zweitprüfer',
        },

        placeholder: {
          hinweisZweitpruefer: 'Bitte verfassen Sie einen internen Hinweis an den Zweitprüfer...',
        },
        subText:
          'Nachdem Sie die Erstprüfung abgeschlossen haben, wird eine neue (für Sie nicht sichtbare) Aufgabe zur Zweitprüfung erstellt.',
      },
    },
  },
};

export const avalZweitpruefungWorkflow: AvalgeberAvalWorkflowAvalZweitpruefenLocaleSchema = {
  contentHeader: 'Avalentwurf prüfen (Zweitprüfung)',
  banner: {
    versandInfo: {
      header: 'Versandart berücksichtigen: Aval in Papierform',
      text: 'Da der Begünstigte nicht auf Trustlog registriert ist, ist eine digitale Ausstellung über Trustlog nicht möglich. Bitte stellen Sie das Aval in Papierform aus.',
      zusatzDirekt:
        'Bitte versenden Sie das Aval an den <strong>Begünstigten</strong>. Versandadresse gemäß Aval.',
      zusatzAbweichend: 'Bitte versenden Sie das Aval an folgende Adresse:',
    },
    versandWarnung: {
      header: 'Versandart berücksichtigen: Digitales Aval über Trustlog',
      text: 'Da der Begünstigte an Trustlog teilnimmt, wird das Aval digital über Trustlog ausgestellt.',
    },
  },
  steps: {
    details: {
      title: 'Avaldaten und Text prüfen',
      pruefAufforderung:
        'Bitte prüfen Sie den unten stehenden Avalentwurf und wählen Sie auf der nächsten Seite aus, ob Sie diesen akzeptieren oder ablehnen möchten.',
      korrektur: {
        pruefKorrektur:
          'Der Begünstigte hat das Aval nach Prüfung mit Korrekturwunsch abgelehnt. Das korrigierte Aval wurde bereits durch den Erstprüfer als Entwurf angelegt.',
        korrekturwunsch: 'Korrekturwunsch anzeigen',
        modal: {
          title: 'Aval mit Korrekturwünsche abgelehnt',
          beschreibung:
            'Der Begünstigte hat das Aval nach Prüfung mit folgendem Änderungswunsch abgelehnt:',
          nachricht: 'Es wurde folgender Korrekturwunsch erfasst:',
        },
      },
      pruefungErgebnis: {
        angepasst: 'angepasst',
        akzeptiert: 'Auftrag ohne Anpassungen akzeptiert',
        erstpruefung: {
          beschreibungOhneAnpassung: 'Die Erstprüfung durch {pruefer} ergab folgende Entscheidung:',
          beschreibungMitAnpassung:
            'Die bereits durchgeführte Erstprüfung ergab folgende Entscheidung:',
        },
        zweitpruefung: {
          beschreibungOhneAnpassung:
            'Eine Zweitprüfung durch {pruefer} ergab folgende Entscheidung:',
          beschreibungMitAnpassung:
            'Eine Zweitprüfung hat bereits stattgefunden und ergab folgende Entscheidung:',
        },
        pruefungsDaten: {
          auftraggeber: {
            name: 'Begünstigter',
            postadresse: {
              straße: 'Straße (Begünstigter)',
              hausnummer: 'Hausnummer (Begünstigter)',
              postleitzahl: 'Postleitzahl (Begünstigter)',
              ort: 'Ort (Begünstigter)',
              landeskennzeichen: 'Landeskennzeichen (Begünstigter)',
            },
          },
          auftragnehmer: {
            name: 'Kreditnehmer',
            postadresse: {
              straße: 'Straße (Kreditnehmer)',
              hausnummer: 'Hausnummer (Kreditnehmer)',
              postleitzahl: 'Postleitzahl (Kreditnehmer)',
              ort: 'Ort (Kreditnehmer)',
              landeskennzeichen: 'Landeskennzeichen (Kreditnehmer)',
            },
          },
          buergschaftsart: 'Avalart',
          buergschaftsbetrag: 'Avalbetrag',
          vertragsdatum: 'Vertragsdatum',
          vertragsnummer: 'Vertragsnummer',
          befristungsdatum: 'Befristungsdatum',
          voraussichtlichesAblaufdatum: 'Voraussichtl. Ablaufdatum',
          bauleistung: 'Leistungsbeschreibung',
          bauvorhaben: {
            freitext: 'Ort der Leistung',
            adresse: {
              straße: 'Ort der Leistung (Straße)',
              hausnummer: 'Ort der Leistung (Hausnummer)',
              postleitzahl: ' Ort der Leistung (PLZ)',
              ort: 'Ort der Leistung (Ort)',
              landeskennzeichen: 'Ort der Leistung (Landeskennzeichen)',
            },
          },
          buergschaftstemplateId: 'Texte',
        },
      },
    },

    entscheidung: {
      title: 'Avalentwurf akzeptieren?',
      beschreibung:
        'Wählen Sie aus, ob Sie den unten stehenden Avalentwurf akzeptieren oder ablehnen möchten.',
      fehlermeldung: 'Bitte wählen Sie eine Option aus.',

      radioOptions: {
        first: {
          title: 'Ja, Avalentwurf akzeptieren',
          subText:
            'Sie akzeptieren den Avalentwurf wie unten dargestellt. Passen Sie bei Bedarf im nächsten Schritt die Avalnummer und das Ausstellungsdatum.',
        },

        second: {
          title: 'Ja, Avalentwurf mit Anpassungen akzeptieren',
          subText:
            'Sie akzeptieren den Avalentwurf wie unten dargestellt, nur mit einer oder mehreren Anpassungen. Sie können die Avaldaten im nächsten Schritt bearbeiten und den Avalentwurf erneut in der Zweitprüfung geben.',
        },

        third: {
          title: 'Nein, Avalentwurf ablehnen',
          banner: 'Avalentwürfe, die auf einer Korrektur basieren, können nicht abgelehnt werden',
          subText:
            'Sie lehnen den Avalentwurf ab und können optional den Ablehnungsgrund und einen Ansprechpartner angeben.',
        },
      },
    },

    bearbeiten: {
      title: 'Avaldaten bearbeiten',
      beschreibung:
        'In diesem Schritt können Sie die Daten aus dem Avalentwurf so bearbeiten, wie sie nachher auf dem Aval erscheinen sollen.',
      fehlermeldung:
        'Passen Sie mindestens einen Wert an oder wählen Sie im vorherigen Schritt die Option ‘Ja, Avalentwurf akzeptieren’',
      dokumentFehlermeldung:
        'Bitte laden Sie ein neues Avaldokument hoch oder wählen Sie einen Text aus.',
    },

    ablehnen: {
      title: 'Avalentwurf ablehnen',
      beschreibung:
        'Sie lehnen den Avalentwurf und damit den Avalauftrag ab. Geben Sie optional Kontaktdaten eines Ansprechpartners und einen Ablehnungsgrund ein.',
      form: {
        label: {
          ablehnungsgrund: 'Ablehnungsgrund',
          primaryButton: 'Avalentwurf ablehnen',
        },
        placeholder: { ablehnungsgrund: 'Beschreiben Sie den Grund für die Ablehnung …' },
      },
    },

    akzeptieren: {
      fehlermeldung:
        'Passen Sie mindestens einen Wert an oder wählen Sie im vorherigen Schritt die Option ‘Ja, Avalentwurf akzeptieren’',
      ohneAnpassung: {
        title: 'Avalentwurf akzeptieren',
        beschreibung:
          'Ergänzen Sie die Avalnummer und das Ausstellungsdatum oder passen Sie diese bei Bedarf an.',
      },
      mitAnpassung: {
        title: 'Avalentwurf mit Anpassungen akzeptieren',
        beschreibung: 'Bitte tragen Sie abschließend die Avalnummer und das Ausstellungsdatum ein.',
      },

      form: {
        label: {
          hinweis: 'Hinweis an Zweitprüfer',
          buttons: {
            primary: {
              abschliessen: 'Zweitprüfung abschließen',
              ausstellen: 'Aval ausstellen',
            },
          },
        },
        placeholder: {
          hinweis: 'Bitte verfassen Sie einen internen Hinweis an den Zweitprüfer...',
        },
      },

      footer: {
        ohneAnpassung: {
          lieferkanalPost:
            'Nachdem Sie die Zweitprüfung abgeschlossen haben, wird der Kreditnehmer über den akzeptierten Avalauftrag informiert.',
          lieferkanalTrustlog:
            'Nachdem Sie das Aval ausgestellt haben, wird dieses dem Begünstigten über Trustlog zugestellt.',
        },
        mitAnpassung:
          'Nachdem Sie die Erstprüfung abgeschlossen haben, wird eine neue (für Sie nicht sichtbare)  Aufgabe zur Zweitprüfung erstellt.',
      },
    },
  },
};

export const avalKorriegernWorkflow: AvalKorrigieren = {
  contentHeader: 'Aval korrigieren',
  korrekturwunsch: {
    contentHeader: 'Aval mit Korrekturwünschen abgelehnt',
    beschreibung:
      'Der Begünstigte hat das Aval nach Prüfung mit folgendem Änderungswunsch abgelehnt:',
    nachricht: 'Es wurde folgender Korrekturwunsch erfasst:',
  },
  daten: {
    contentHeader: 'Aval bearbeiten',
    beschreibung: 'Bitte bearbeiten Sie die anzupassenden Daten.',
    keineAenderungFehler: 'Passen Sie mindestens einen Wert an.',
    dokumentFehler: 'Bitte laden Sie ein neues Avaldokument hoch oder wählen Sie einen Text aus.',
  },
  pruefung: {
    contentHeader: 'Änderungen prüfen',

    standard: {
      beschreibung:
        'Bitte überprüfen Sie in der unten aufgeführten Avalvorschau die von Ihnen ' +
        'korrigierten Daten. Tragen Sie anschließend die neue Avalnummer und das neue ' +
        'Ausstellungsdatum ein.',
      footer:
        'Nachdem Sie das Aval erneut ausgestellt haben, wird diese dem Begünstigen direkt zur Prüfung übermittelt.',
      primaryAction: '@:shared.erneutAusstellenButton',
    },

    vierAugenPrinzip: {
      beschreibung:
        'Ergänzen Sie die Avalnummer und das Ausstellungsdatum ein, um das Aval zur Zweitprüfung weiterzuleiten.',
      footer:
        'Nachdem Sie die Erstprüfung abgeschlossen haben, wird eine neue (für Sie nicht sichtbare)  Aufgabe zur Zweitprüfung erstellt.',
      primaryAction: 'Erstprüfung abschließen',
    },

    form: {
      label: {
        hinweisZweitpruefer: 'Hinweis an Zweitprüfer',
        ausstellungsDatum: 'Ausstellungsdatum',
        avalnummer: 'Avalnummer',
      },
      placeholder: {
        hinweisZweitpruefer: 'Bitte verfassen Sie einen internen Hinweis an den Zweitprüfer...',
      },
    },

    uebergeordneterFehler: 'Bitte füllen Sie alle erforderlichen Felder aus',
  },
};
