import { SharedAufgabenService } from '@/shared/api/services/aufgaben';
import { SharedBuergschaftService } from '@/shared/api/services/buergschaft';
import type { IHttpClient } from '@/shared/api/clients/HttpClient';
import { SharedDokumentService } from '@/shared/api/services/dokument';
import { SharedProjektService } from '@/shared/api/services/projekt';
import { SharedVirenScannerService } from '@/shared/api/services/virenScanner';
import { SharedPartnerService } from '@/shared/api/services/partner/partner';
import { SharedPlattformHinweisService } from '@/shared/api/services/plattformHinweise';
import { SharedAvalPartnerKonfiguratorService } from '@/shared/api/services/avalpartnerkonfigurator/avalpartnerkonfigurator';

export class SharedHttpServiceProvider {
  private readonly httpClient: IHttpClient;

  public readonly sharedAufgaben: SharedAufgabenService;

  public readonly sharedBuergschaft: SharedBuergschaftService;

  public readonly sharedDokument: SharedDokumentService;

  public readonly sharedProjekt: SharedProjektService;

  public readonly sharedVirenScanner: SharedVirenScannerService;

  public readonly sharedPartner: SharedPartnerService;

  public readonly sharedAvalPartnerKonfigurator: SharedAvalPartnerKonfiguratorService;

  public readonly sharedPlattformHinweis: SharedPlattformHinweisService;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
    this.sharedAufgaben = new SharedAufgabenService(this.httpClient);
    this.sharedBuergschaft = new SharedBuergschaftService(this.httpClient);
    this.sharedDokument = new SharedDokumentService(this.httpClient);
    this.sharedProjekt = new SharedProjektService(this.httpClient);
    this.sharedVirenScanner = new SharedVirenScannerService(this.httpClient);
    this.sharedPartner = new SharedPartnerService(this.httpClient);
    this.sharedAvalPartnerKonfigurator = new SharedAvalPartnerKonfiguratorService(this.httpClient);
    this.sharedPlattformHinweis = new SharedPlattformHinweisService(this.httpClient);
  }
}
