import type { LocaleSchema } from '@/lang/types/types.ts';
import {
  avalKorriegernWorkflow,
  avalZweitpruefungWorkflow,
  pruefenWorkflow,
} from './de-DE/avalgeber.ts';
import { inanspruchnahmeWorkflow } from './de-DE/auftraggeber';

const deLocale: LocaleSchema = {
  shared: {
    components: {
      modal: {
        pdfPreview: {
          header: 'Textvorschau',
        },
      },
    },
    profil: {
      nutzerstatus: {
        beschreibungBerechtigung:
          'Mit einem aktiven Status sind Sie berechtigt die Bürgschaftsplattform zu nutzen.',
        beschreibungDeaktivierung:
          'Durch Deaktivierung haben Sie keinen Zugriff mehr auf die Plattform.',
      },
    },
    tabelle: {
      filter: {
        filterLoeschen: 'Filter löschen',
        ausgewaehlt: 'ausgewählt',
        auswahlLoeschen: 'Auswahl löschen',
        anwenden: 'Anwenden',
        zuruecksetzen: 'Filter zurücksetzen',
        von: 'von',
        bis: 'bis',
      },
      pagination: {
        seite: 'Seite',
        proSeite: 'pro Seite',
      },
      emptyStateText: 'Keine entsprechenden Ergebnisse wurden gefunden.',
    },
    euro: 'Euro',
    keineAngabe: 'keine Angabe',
    erneutAusstellenButton: 'Erneut ausstellen',
    zurueck: 'Zurück',
    absenden: 'Absenden',
    senden: 'Senden',
    speichern: 'Speichern',
    abbrechen: 'Abbrechen',
    weiter: 'Weiter',
    hinzufuegen: 'Hinzufügen',
    erfolgreichhinzugefuegt: 'wurde erfolgreich hinzugefügt.',
    loeschen: 'Ja, löschen',
    nutzereinstellung: 'Zur Nutzereinstellung',
    alleauswaehlen: 'Alle auswählen',
    alleabwaehlen: 'Alle abwählen',
    unternehmen: 'Unternehmen',
    adresse: 'Adresse',
    keineEingabe: '[Keine Eingabe]',
    aktionen: 'Aktionen',
    historie: 'Historie',
    ablehnen: 'Ablehnen',
    akzeptieren: 'Akzeptieren',
    schliessen: 'Schließen',
    aktualisieren: 'Aktualisieren',
    zuDenAufgaben: 'Zu den Aufgaben',
    dokumentationHerunterladen: 'Beschlussdokumentation herunterladen',
    gesperrtPopup:
      'Diese Ansicht wurde zwischenzeitlich bearbeitet. Bitte aktualisieren Sie die Seite.',
    zeichen: 'Zeichen',
    von: 'von',
    anschrift: 'Anschrift',
    ausstellungsdatum: 'Ausstellungsdatum',
    befristung: 'Befristung',
    unbefristet: 'Unbefristet',
    befristet: 'Befristet',
    vertretenDurch: 'Vertreten durch:',
    letzlichVertretenDurch: 'Letztlich vertreten durch:',
    vertragsdatum: 'Vertragsdatum',
    baurecht: 'Baurecht',
    mitDemMuster: 'Bürgschaftsbeantragung mit dem Muster',
    antragAnzeigen: 'Antrag anzeigen',
    textAnzeigen: 'Text anzeigen',
    avalentwurfAnzeigen: 'Avalentwurf anzeigen',
    sonstigerText: 'Sonstiger Text',
    buergschaftsantrag: 'Bürgschaftsantrag',
    avalauftrag: 'Avalauftrag',
    auftragsdaten: 'Auftragsdaten',
    optional: 'optional',
    bearbeitet: 'bearbeitet',
    bearbeiten: 'Bearbeiten',
    aenderungSpeichern: 'Änderungen speichern',
    navigation: {
      hilfe: 'Hilfe und Support',
      abmelden: 'Abmelden',
      sitzungsdauer: 'Sitzungsdauer',
    },
    nutzerundrollen: {
      ueberschrift: 'Nutzer und Rollen',
      nutzer: {
        ueberschrift: 'Nutzer',
        hinzufuegen: 'Neuer Nutzer',
        neuernutzerhinzu: 'Neuen Nutzer hinzufügen',
        nutzerinnen: 'Nutzer:innen',
        zuallennutzern: 'Zu allen Nutzern',
        weiter: 'Weiter',
        warnung: {
          bereitsvorhanden: {
            header: 'Nutzer bereits vorhanden',
            text: 'Die von Ihnen angegebenen E-Mail Adresse wurde bereits zur Anlage eines Nutzers Ihres Unternehmens verwendet.\n              Bitte ändern Sie die E-Mail Adresse oder brechen Sie den Prozess ab.',
          },
          systemrolle: {
            header: 'Systemrolle zugewiesen',
            text: 'Dieser Nutzer kann nicht gelöscht werden, da diesem aktuell eine nicht bearbeitbare Systemrolle\n              zugewiesen ist. Um den Nutzer zu löschen, weisen Sie ihm bitte eine andere Rolle zu. Achtung, dies\n              hat möglicherweise Auswirkungen auf die Funktionsweise der Plattform.',
          },
          aenderung: {
            text: 'Bitte nehmen Sie mindestens eine Änderung vor, um den Nutzer speichern zu können\n              oder brechen Sie den Prozess ab.',
          },
          wiederhergestellt: {
            header: 'E-Mail-Adresse wurde bereits verwendet',
            text: 'Die von Ihnen angegebenen E-Mail Adresse wurde bereits zur Anlage eines Nutzers Ihres Unternehmens verwendet.\n              Der entsprechende Nutzer wurde gelöscht.  Bitte ändern Sie die E-Mail Adresse, stellen Sie den Nutzer wieder\n              her oder brechen Sie den Prozess ab.',
            button1Text: 'Konto wiederherstellen',
            frage: 'Möchten Sie diesen Nutzer wirklich wiederherstellen?',
            buttonja: 'Ja, wiederherstellen',
          },
        },
        erfolg: {
          nutzerGeloescht: 'Das Löschen des Nutzerprofils {vorname} {nachname} war erfolgreich.',
          nutzerAngelegt: '{vorname} {nachname} wurde erfolgreich hinzugefügt.',
          nutzerGespeichert:
            'Die Änderungen an dem Nutzerprofil {vorname} {nachname} wurden gespeichert.',
          nutzerWiederhergestellt: '{vorname} {nachname} wurde erfolgreich wiederhergestellt.',
        },
        aenderung: {
          status: 'Durch Aktivieren ist der Nutzer berechtigt, digitale Bürgschaften zu verwalten.',
          aktiv: 'Aktiv',
          inaktiv: 'Inaktiv',
          beschreibungBerechtigung:
            'Durch Aktivieren ist der Nutzer berechtigt, digitale Bürgschaften zu verwalten.',
          beschreibungDeaktivierung:
            'Durch Deaktivierung hat der Nutzer keinen Zugriff mehr auf die Plattform.',
          speichern: 'Nach dem Speichern wird der entsprechende Status übernommen.',
          gespeichert:
            'Die Änderungen an dem Nutzerprofil {vorname} {nachname} wurden gespeichert.',
          loeschen: 'Das Löschen des Nutzerprofils {vorname} {nachname} war erfolgreich.',
          angepasst: 'Die Änderungen an dem Nutzerprofil {vorname} {nachname} wurden angepasst.',
          deaktiviert: 'Der Nutzer {vorname} {nachname} wurden deaktiviert.',
          reaktivieren:
            'Der Nutzer kann wieder reaktiviert werden und wieder Zugriff auf die Plattform erhalten.',
          ueberschrift: 'Aktivitätsstatus des Nutzers',
        },
        loeschen: 'Nutzer löschen',
        bearbeiten: 'Nutzer bearbeiten',
        zuallenutzern: 'Zu allen Nutzern',
      },
      rollen: {
        ueberschrift: 'Rollen',
        ueberschrifthinzufuegen: 'Neue Rolle hinzufügen',
        hinzufuegen: 'Neue Rolle',
        nutzerrolle: 'Nutzerrolle',
        rollenbezeichnung: 'Bezeichnung der Rolle',
        zugeordnetenutzer: 'Zugeordnete Nutzer',
        aenderungswarnung:
          'Bitte nehmen Sie mindestens eine Änderung vor, um die Rolle speichern zu können\n              oder brechen Sie den Prozess ab.',
        loeschen: 'Rolle löschen',
        loeschenfrage: 'Möchten Sie wirklich die Rolle löschen?',
        loeschenunmoeglich:
          'Diese Rolle kann nicht gelöscht werden, da ihr noch mindestens ein Nutzer zugeordnet ist.\n                Um die Rolle zu löschen, wechseln Sie bitte zu den Nutzereinstellungen und entfernen Sie\n                alle zugeordneten Nutzer.',
        rollenname: 'Name der Rolle',
        allerollen: 'Zu allen Rollen',
        formular: {
          name: 'Wie soll die neue Rolle heißen?',
          rollenname: 'Name der Rolle',
          avatar: 'Wählen Sie einen Avatar für die Rolle',
          avatarfehler: 'Bitte wählen Sie einen Avatar aus',
          berechtigung: 'Wählen Sie die Berechtigungen für die neue Rolle',
        },
        rechtegruppe: {
          anträge: 'Anträge',
          bürgschaften: 'Bürgschaften',
          aufgaben: 'Aufgaben',
          mitteilungen: 'Mitteilungen',
          projekte: 'Projekte',
          unternehmen: 'Unternehmen',
          verträge: 'Verträge',
          texte: 'Texte',
          nutzer: 'Nutzer',
          rollen: 'Rollen',
        },
      },
    },
    kontaktdaten: {
      ueberschrift: 'Kontaktdaten',
      defaultHeader: 'Kontaktdaten des Ansprechpartners',
      ansprechpartnerBeiRueckfragen: 'Ansprechpartner bei Rückfragen',
      automatischAusfuellen: 'Kontaktdaten automatisch ausfüllen',
      meineKontaktdatenEintragen: 'Meine Kontaktdaten eintragen',
      vorname: 'Vorname',
      nachname: 'Nachname',
      email: 'E-Mail Adresse',
      telefonnummer: 'Telefonnummer',
      telefonnummerOptional: 'Telefonnummer (optional)',
      fehler: 'Bitte füllen Sie alle erforderlichen Felder aus',
    },
    ansprechpartner: {
      stammdaten: 'Stammdaten',
      header: 'Ihre Ansprechpartner für Rückfragen',
      name: 'Name',
      email: 'Email',
      telefonnummer: 'Telefonnummer',
    },
    pdfEmbed: {
      keineUnterstuetzung: 'Ihr Browser unterstützt kein PDF. ',
      falscherDokumentyp: 'Der Dokumententyp der Vorlage kann nicht im Browser dargestellt werden.',
      downloadText: 'Hier ist der ',
      downloadLink: 'Download-Link',
    },
    avalartAnzeigetextMap: [
      'Mängelansprüche',
      'Vertragserfüllung',
      'An- & Vorauszahlung',
      'Ausführung',
      'Anzahlungen',
      'Arbeitszeitkonten',
      'Ausland',
      'Ausschüttungen',
      'Bauhandwerkersicherung',
      'Bietung',
      'BImSchG',
      'Branntwein',
      'Deckungs-/Haftungsrücklass',
      'Deckungsrücklass',
      'Erschließung',
      'Fertigstellungs-/Haftungsrücklass',
      'Fertigstellung',
      'Fracht',
      'Garantie',
      'Gewährleistung',
      'Haftungsrücklass',
      'IATA',
      'Lieferanten',
      'MaBV',
      'Miete',
      'Mineralöl',
      'Pacht',
      'Prozess',
      'Rekultivierung',
      'Rück',
      'Sonstige',
      'Sonstige Garantie',
      'Tankkarten',
      'Vertragserfüllungsgarantie',
      'Zahlung',
      'Zoll',
      'Allgemein',
    ],
    auftragStatusAnzeigetextTabelleMap: [
      'Storniert',
      'Ausgestellt',
      'Abgelehnt',
      'Unvollständig',
      'Ungeprüft',
      'In Prüfung',
    ],
    templatetypAnzeigetextTextartMap: ['Begünstigtentext', 'Öffentlicher Text', 'Avalgebertext'],
    auftraege: {
      uebersicht: {
        infobar: {
          status: 'Status',
          auftragsnummer: 'Antragsnr.',
          vertragsnummer: 'Vertragsnr.',
          erstellt: 'Erstellt',
          ablaufdatum: 'Vorauss. Ablauf',
        },
      },
    },
    fehlerPopup: {
      title: 'Oops! Etwas ist schief gelaufen.',
      text: 'Bitte leiten Sie den Fehlercode { correlationId } an Ihren Trustlog-Ansprechpartner weiter. So helfen Sie uns den Fehler zu analysieren und zu beheben.',
    },
    felder: {
      avalnummer: 'Avalnummer',
      beguenstigter: 'Begünstigter',
      aval: 'Avaldaten',
      grundgeschaeft: 'Grundgeschäft',
      laufzeit: 'Laufzeit',
      leistungsbeschreibung: 'Leistung',
      ortDerLeistung: 'Ort der Leistung',
      ausstellungsdatum: 'Ausstellungsdatum',
      avaldokument: 'Avaldokument',
      ausstellungsdaten: 'Ausstellungsdaten',
      avalart: 'Avalart',
      betrag: 'Avalbetrag',
      ablaufdatumOptional: 'Voraussichtl. Ablaufdatum (optional)',
      befristungsdatum: 'Befristungsdatum',
      vertragsdatum: 'Vertragsdatum',
    },
    unternehmenAdresse: {
      name: 'Name der Firma oder Person',
      adresszusatz: 'Adresszusatz (optional)',
      strasse: 'Straße',
      hausnummer: 'Hausnummer',
      plz: 'PLZ',
      ort: 'Ort',
      land: 'Land',
    },
    avale: {
      common: {
        ablehnenAenderungsGruende: {
          grund: {
            abgelehnt: 'Bürgschaft ablehnen',
            beguenstigter: 'Fehlerhafter Auftraggeber',
            text: 'Fehlerhafter oder nicht gewünschter Text',
            betrag: 'Fehlerhafter Bürgschaftsbetrag',
            artDerArbeit: 'Fehlerhafte oder unvollständige Art der Arbeit',
            ortDerArbeit: 'Fehlerhafter Ort der Arbeit',
            sonstige: 'Sonstige Änderungswünsche',
          },
        },
      },
    },
  },
  auftraggeber: {
    aufgaben: {
      liste: {
        contentHeader: 'Aufgaben',
      },
    },
    buergschaften: {
      workflows: {
        inanspruchnahme: inanspruchnahmeWorkflow,
      },
    },
    buergschaftsantraege: {},
    buergschaftstexte: {},
    mitteilungen: {},
    nutzer: {},
    projekte: {},
    unternehmen: {},
    vertraege: {},
  },
  avalgeber: {
    navigation: {
      label: {
        arbeitsbereich: 'Mein Arbeitsbereich',
        aval: 'Avalverwaltung',
        teilnehmer: 'Teilnehmerverwaltung',
        unternehmen: 'Mein Unternehmen',
      },
      title: {
        aufgaben: 'Aufgaben',
        avale: 'Avale',
        auftraege: 'Aufträge',
        texte: 'Texte',
        teilnehmer: 'Teilnehmer',
        vertraege: 'Avalkreditverträge',
        nutzer: 'Nutzer und Rollen',
      },
    },
    aufgaben: {
      liste: {
        contentHeader: 'Aufgaben',
        filter: {
          label: ['Betreff', 'Eingangsdatum'],
          filterOptions: {
            betreff: [
              'Begünstigtentext Erstprüfung',
              'Begünstigtentext Zweitprüfung',
              'Avaldokument hochladen',
              'Aval korrigieren',
              'Ablehnung des Avals bestätigen',
              'Unterlagen Inanspruchnahme herunterladen',
              'Neue Vertragszuordnung prüfen',
              'Auftrag prüfen',
              'Avalentwurf prüfen (Zweitprüfung)',
            ],
          },
        },
        spalten: {
          header: {
            betreff: 'Betreff / Eingangsdatum',
            absender: 'Absender',
          },
          betreff: [
            'Neuen {textart} prüfen',
            'Neuen Begünstigtentext prüfen',
            'Zweitprüfung {textart}',
            'Zweitprüfung Begünstigtentext',
            'Auftrag prüfen',
            'Avaldokument hochladen',
            'Aval korrigieren',
            'Ablehnung des Avals bestätigen',
            'Unterlagen zur Inanspruchnahme herunterladen',
            'Neue Vertragszuordnung prüfen',
            'Avalentwurf prüfen (Zweitprüfung)',
          ],
          absender: {
            beguenstigter: 'Begünstigter',
            kreditnehmer: 'Kreditnehmer',
            trustlog: 'Trustlog (System)',
          },
        },
        defaultEmptyStateText:
          'Hier werden Ihre Aufgaben angezeigt, sobald Texte hinterlegt oder Avale von Ihren Nachunternehmern eingereicht wurden.',
        banner: {
          auftrag: {
            zweitpruefung: {
              ohneAnpassung:
                'Sie haben den Auftrag akzeptiert. Das Aval liegt nun zur Zweitprüfung vor.',
              mitAnpassung:
                'Sie haben den Auftrag mit Anpassungen akzeptiert. Das Aval liegt nun zur Zweitprüfung vor.',
            },
          },
          avalKorrigiert: {
            erstpruefung:
              'Das Aval wurde korrigiert, ein Avalentwurf liegt nun zur Zweitprüfung vor.',
            zweitpruefung: {
              ohneAnpassung:
                'Die Neuausstellung des Avals wurde akzeptiert. Der Begünstigte wurde informiert.',
              mitAnpassung:
                'Der Avalentwurf wurde mit Anpassungen akzeptiert und erneut zur Zweitprüfung weitergeleitet.',
            },
          },
          avalpruefung: {
            zweitpruefung: {
              ohneAnpassung:
                'Der Avalauftrag wurde akzeptiert. Der Kreditnehmer wurde über die Ausstellung des Avals informiert.',
              mitAnpassung:
                'Der Avalentwurf wurde mit Anpassungen akzeptiert und erneut zur Zweitprüfung weitergeleitet.',
              abgelehnt:
                'Der Avalentwurf wurde abgelehnt. Der Kreditnehmer wurde über die Ablehnung informiert.',
            },
          },
        },
      },
    },
    avale: {
      textarten: {
        avalgebertext: 'Avalgebertext',
        oeffentlicherText: 'Öffentlicher Text',
        dokumentHochladen: 'Avaldokument hochladen',
      },
      liste: {
        contentHeader: 'Avale',
        tab: {
          beguenstigtenavale: 'Nach Trustlog-Begünstigten',
          kreditnehmeravale: 'Nach Trustlog-Kreditnehmern',
          pdfavale: 'PDF-Avale',
        },
        banner: {
          text: 'Das Aval für { beguenstigterName } wurde ausgestellt.',
          button: 'Aval öffnen',
        },
      },
      details: {
        contentHeader: {
          manuellHochladen: 'Avaldokument hochladen',
          ablehnungBestaetigen: 'Avalablehnung bestätigen',
          dokumentHochladen: 'Dokument hochladen',
          dokumentHochgeladen: 'Dokument hochgeladen',
          inanspruchnahmeHerunterladen: 'Unterlagen zur Inanspruchnahme herunterladen',
          detail: 'Aval im Detail',
        },
        meldungen: {
          inanspruchnahme: {
            header: 'Das Herunterladen der Unterlagen zur Inanspruchnahme wurde gestartet.',
            button: 'Zu den Aufgaben',
          },
        },
        banner: {
          auftragAkzeptiert: {
            button: {
              oeffnen: 'Auftrag öffnen',
            },
            text: {
              ausgestellt:
                'Die Verarbeitung des Avaldokuments benötigt im Hintergrund noch einige Sekunden. Um ' +
                'das Dokument sowie den neuen Status zu sehen, aktualisieren Sie bitte die Ansicht.',
              akzeptiert: 'Sie haben den Auftrag für dieses Aval akzeptiert.',
              entwurf: 'Dieser Avalentwurf wartet auf Zweitprüfung.',
            },
            beschreibung: {
              digitalNichMoeglich:
                'Da der Begünstigte nicht auf Trustlog registriert ist, ist eine digitale Ausstellung ' +
                'über Trustlog nicht möglich. ',
              abweichendeVersandadresse:
                'Der Auftragsteller hat eine abweichende Versandadresse angegeben. Bitte versenden ' +
                'Sie das Aval an folgende Adresse:',
              anBeguenstigtenVersenden:
                'Bitte versenden Sie das Aval an den <b>Begünstigten</b>. Versandadresse gemäß ' +
                'Aval.',
              anKreditnehmerVersenden:
                'Bitte versenden Sie das Aval an den <b>Kreditnehmer</b>. Versandadresse gemäß ' +
                'Aval.',
              adresszusatz: 'Bitte verwenden Sie folgenden Adresszusatz',
              beguenstigterAufTrustlog:
                'Da der Begünstigte an Trustlog teilnimmt, liegt diesem das Aval bereits zur Prüfung vor.',
            },
          },
        },
        aktionen: {
          dokumentHochladen: {
            button: 'Dokument hochladen',
            title: 'Avaldokument hochladen',
          },
          ablehnungBestaetigen: {
            button: 'Bestätigen',
            title: 'Ablehnung bestätigen',
          },
          korrigieren: {
            button: 'Korrigieren',
            title: 'Aval korrigieren',
          },
          inanspruchnahmeHerunterladen: {
            button: 'Herunterladen',
            title: 'Unterlagen zur Inanspruchnahme herunterladen',
          },
        },
        toast: {
          text: 'Bitte laden Sie ein neues Avaldokument unter Aktionen manuell hoch.',
        },
        infobar: {
          status: 'Status',
          vertragsnummer: 'Vertrag',
          auftragsnummer: 'Auftrag',
          ablaufdatum: 'Vorauss. Ablauf',
          ansprechpartner: 'Ansprechpartner',
        },
      },
      uebersicht: {
        banner: {
          herkunft: {
            header: 'Herkunft des Avals',
            text:
              'Das Aval wurde nicht in Trustlog erstellt, sondern vom Begünstigten hochgeladen. Für ' +
              'die Angaben ist der Begünstigte selbst verantwortlich.',
          },
        },
        header: {
          betrag: 'Avalbetrag',
          ausstellungsdatum: 'Ausstellungsdatum',
          befristung: 'Befristung',
          auftragnehmer: 'Kreditnehmer',
          auftraggeber: 'Begünstigter',
          vertragsdaten: 'Grundgeschäft',
          artDerArbeit: 'Leistung',
          muster: 'Aval nach dem Muster',
          weitereAvalEigenschaften: 'Weitere Avaleigenschaften',
          ortDerArbeit: 'Ort der Leistung',
        },
        tooltip: {
          teilenthaftet: 'Das Aval wurde teilenthaftet. Details finden Sie in der Historie.',
          gewaehlterText: 'Das Aval wurde mit dem vom Begünstigten gewählten Text erstellt.',
          ohneGewaeltenAktuellenText:
            'Das Aval wurde nicht mit dem vom Begünstigten gewählten aktuellen Text erstellt.',
          ohneGewaehltenText:
            'Das Aval wurde nicht mit dem vom Begünstigten gewählten Text erstellt.',
        },
        vertretenDurch: 'Letztlich vertreten durch',
        vertragsDatum: 'Vertragsdatum',
        vertragsNummer: 'Zeichen',
        baurecht: 'Baurecht',
        achtung: 'Achtung',
        beschreibung: {
          mitPdf:
            'Bei dieser Ansicht handelt es sich um eine Übersicht der Avaldaten. Das Aval mit ' +
            'rechtsverbindlichem Inhalt können Sie in der PDF-Ansicht einsehen.',
          ohnePdf: 'Bei dieser Ansicht handelt es sich um eine Übersicht der Avaldaten.',
        },
        zurPdfWechseln: 'Zur PDF-Ansicht wechseln',
        zahlungAufErstesAnfordern: 'Zahlung auf erstes Anfordern',
        footer: 'Das Aval kann nicht mehr verändert werden',
        templateBeschreibungSonstigerText: 'Sonstiger Text',
        aktionen: {
          korrigieren: 'Aval korrigieren',
          pruefen: 'Avalentwurf prüfen',
          auftrag: 'Auftrag öffnen',
          ablehnungBestaetigen: 'Ablehnung bestätigen',
          dokumentHochladen: 'Dokument hochladen',
          inanspruchnahmeHerunterladen: 'Daten zur Inanspruchnahme herunterladen',
          pdfExport: 'Als PDF exportieren',
          pdfVorschau: 'Als PDF exportieren',
        },
        button: {
          detailansicht: 'Zur Detailansicht',
          pdfAnsicht: 'Zur PDF-Ansicht',
          textVorschau: 'Text anzeigen',
        },
      },
      aktionen: {
        ablehnen: {
          whitebox: {
            title: 'Ablehnung des Avals mitgeteilt',
            beschreibung:
              'Der Begünstigte lehnt das Aval ab. Sie bestätigen hiermit die Ablehnung zur Kenntnis ' +
              'genommen und das Aval in Ihrem System storniert zu haben.',
            buttons: {
              ablehnungBestaetigen: 'Ja, Ablehnung bestätigen',
            },
          },
          erfolgsmeldung: {
            header: 'Sie haben die Ablehnung des Avals bestätigt.',
            buttonText: 'Zu allen Avalen',
          },
        },
        dokumentHochladen: {
          banner: {
            header: 'Die Datei wird noch geladen',
            text:
              'Das Hochladen Ihrer Datei ist noch nicht abgeschlossen. Bitte warten Sie bis der ' +
              'Vorgang beendet ist um fortzufahren.',
          },
          upload: {
            ueberschrift: 'Avaldokument manuell hochladen',
            dateiHochgeladen: 'Sie haben bereits die maximale Anzahl von Dokumenten ausgewählt',
            wirdHochgeladen: 'Wird hochgeladen ...',
            fehler:
              'Es ist ein Fehler beim Hochladen Ihres Dokuments aufgetreten.<br /> Bitte entfernen ' +
              'Sie diese Datei und versuchen Sie eine in einem anderen Format hochzuladen.',
          },
          bestaetigung: {
            ueberschrift: 'Bestätigung des hochgeladenen Avaldokuments',
            beschreibung:
              'Wollen Sie dieses Dokument hochladen? Das Dokument wird im Anschluss dem Begünstigten ' +
              'übermittelt.',
            dokument: 'Avaldokument',
            button: {
              dokumentAnsehen: 'Dokument ansehen',
              dokumentHochladen: 'Ja, hochladen',
            },
          },
          erfolg: {
            meldung: {
              ueberschrift: 'Das Avaldokument wurde hochgeladen',
              beschreibung:
                'Das Aval wurde manuell hochgeladen und an den Begünstigten übermittelt.',
            },
            button: {
              aufgaben: 'Zu allen Aufgaben',
              buergschaften: 'Zu allen Avalen',
            },
          },
        },
      },
      common: {
        ablehnenAenderungsGruende: {
          contentHeader: {
            text: 'Korrektur',
            beschreibung: 'Folgende Punkte benötigen eine Korrektur',
          },
          hinweis: {
            mitteilung: 'Mitteilung',
          },
          grund: {
            abgelehnt: 'Aval ablehnen',
            beguenstigter: 'Fehlerhafter Begünstigter',
            text: 'Fehlerhafter oder nicht gewünschter Text',
            betrag: 'Fehlerhafter Avalbetrag',
            artDerArbeit: 'Fehlerhafte oder unvollständige Leistungsbeschreibung',
            ortDerArbeit: 'Fehlerhafter Ort der Leistung',
            sonstige: 'Sonstige Änderungswünsche',
          },
        },
        aktionen: {
          listeExportieren: 'Liste exportieren',
          avalAusstellen: 'Aval ausstellen',
        },
        historie: {
          contentHeader: {
            text: 'Historie',
          },
          modal: {
            pruefung: {
              title: {
                akzeptiertOhneAnpassungUndHatHinweis: 'Hinweis an Zweitprüfer',
                akzeptiertMitAnpassung: 'Auftrag mit Anpassungen akzeptiert',
                abgelehnt: 'Ablehnungsgrund',
              },
              body: {
                ansprechpartner: 'Ansprechpartner',
              },
            },
          },
          content: {
            gezeichnet:
              '{ geaendertVon } hat das Aval { buergschaftIdBuerge } gezeichnet. { icon }',
            manuellHochgeladen:
              '{ geaendertVon } hat das Aval { buergschaftIdBuerge } gezeichnet und das ' +
              'Dokument hochgeladen. { icon }',
            repliziert: 'Das Aval { buergschaftIdBuerge } wurde importiert.',
            zuordnungKreditnehmer: '{ geaendertVon } hat das Aval { kreditnehmer } zugeordnet.',
            zuordnungKreditnehmerBold: 'dem Kreditnehmer',
            zuordnungBeguenstigter: '{ geaendertVon } hat das Aval { beguenstigter} zugeordnet.',
            zuordnungBeguenstigterBold: 'dem Begünstigten',
            importiert: '{ geaendertVon } hat das Aval { buergschaftIdBuerge } importiert.',
            akzeptiert: '{ geaendertVon } hat das Aval { buergschaftIdBuerge } akzeptiert.',
            ablehnung: '{ geaendertVon } hat das Aval { buergschaftIdBuerge } abgelehnt.',
            abgewiesen:
              '{ geaendertVon } hat das Aval { buergschaftIdBuerge } mit { link } abgewiesen.',
            abgewiesenLinkText: 'Korrekturanforderung',
            enthaftungGemeldet:
              '{ geaendertVon } hat für das Aval { buergschaftIdBuerge } eine Enthaftung ' +
              'gemeldet.',
            enthaftung: '{ geaendertVon } hat das Aval { buergschaftIdBuerge } enthaftet.',
            teilenthaftungRepliziert:
              'Das Aval { buergschaftIdBuerge } wurde bereits in der Vergangenheit um ' +
              '{ minderungsBetrag } auf { neuerHoechsbetrag } teilenthaftet.',
            teilenthaftungNichtRepliziert:
              '{ geaendertVon } hat das Aval { buergschaftIdBuerge } um { minderungsBetrag } auf jetzt { neuerHoechsbetrag } teilenthaftet.',
            inanspruchnahmeBeantragt:
              '{ geaendertVon } hat für das Aval { buergschaftIdBuerge } eine Inanspruchnahme durchgeführt.',
            inanspruchnahme:
              '{ geaendertVon } hat für das Aval { buergschaftIdBuerge } eine Inanspruchnahme durchgeführt.',
            abtretungVeranlasst:
              '{ geaendertVon } hat eine Abtretung an { zessionarName } { link }.',
            abtretungVeranlasstLinkText: 'veranlasst',
            abtretungAkzeptiertBekannterZessionar: '{ zessionarName } hat die Abtretung { link }.',
            abtretungAkzeptiertBekannterZessionarLinkText: 'akzeptiert',
            abtretungAkzeptiertUnbekannterZessionar:
              '{ zedentName } hat die { link } an { zessionarName} durchgeführt.',
            abtretungAkzeptiertUnbekannterZessionarLinkText: 'Abtretung',
            abtretungAbgelehnt: '{ zessionarName } hat die Abtretung { link }.',
            abtretungAbgelehntLinkText: 'abgelehnt',
            pruefung: '{ geaendertVon } hat das Aval in der { schritt } { link } {akzeptiert}.',
            pruefungLinkTextAbgelehnt: 'abgelehnt',
            pruefungLinkTextMitHinweis: 'einem Hinweis',
            mit: 'mit',
            pruefungLinkTextMitAnpassung: 'mit Anpassungen',
            erstpruefung: 'Erstprüfung',
            zweitpruefung: 'Zweitprüfung',
            weiterenZweitpruefung: 'weiteren Zweitprüfung',
            pruefungTextAkzeptiert: 'akzeptiert',
            rueckforderung: '{ auftragnehmerName } hat die Enthaftung des Avals angefordert.',
            rueckforderungAbgelehnt: '{ auftraggeberName } hat die Enthaftung abgelehnt.',
          },
        },
        tabelle: {
          suchPlatzhalter: 'Suche nach Begünstigten, Kreditnehmer oder Avalnummer',
          emptyText:
            'Hier werden Ihre Avale angezeigt, sobald Texte hinterlegt oder Avale von ' +
            'Ihren Nachunternehmern eingereicht wurden.',
          spalten: {
            avalgeber: {
              header: 'Bürge',
            },
            kreditnehmer: {
              tooltip: {
                ueberschrift1: 'Kreditnehmer',
                uberschrift2: 'Anschrift',
              },
              header: 'Kreditnehmer',
            },
            beguenstigter: {
              tooltip: {
                ueberschrift1: 'Begünstigter',
                uberschrift2: 'Anschrift',
              },
              header: 'Begünstigter',
            },
            buergschaftsart: {
              tooltip: {
                ueberschrift1: 'Avalart',
                uberschrift2: 'Nummer',
              },
              header: 'Art / Nummer',
            },
            ausstellungsDatum: {
              header: 'Ausgestellt',
            },
            ablaufDatum: {
              header: 'Ablauf',
            },
            betrag: {
              tooltip: 'Reduzierter Betrag nach <br />Teilenthaftung.',
              header: 'Betrag',
            },
            status: {
              tooltip: 'Unterlagen zur Inanspruchnahme <br />an den Avalgeber übermittelt.',
              header: 'Status',
            },
          },
        },
        infoHeader: {
          status: 'Status',
          ablaufDatum: 'Voraussichtl. Ablauf',
        },
        vertretungen: 'Vertreten durch:',
        vorschau: {
          digitalGezeichnet: 'Dieses Aval wird vom Avalgeber digital gezeichnet.',
          header: {
            betrag: 'Avalbetrag',
            ausstellungsDatum: 'Ausstellungsdatum',
            befristung: 'Befristung',
            kreditnehmer: 'Kreditnehmer',
            beguenstigter: 'Begünstigter',
            vertragsdaten: 'Grundgeschäft',
            artDerArbeit: 'Leistung',
            ortDerArbeit: 'Ort der Leistung',
          },
          unbefristet: 'Unbefristet',
          vertretung: 'Letztlich vertreten durch:',
          vertragsdatum: 'Vertragsdatum:',
          zeichen: 'Zeichen:',
          achtung: 'Achtung:',
          haftungsausschluss:
            'Bei dieser Ansicht handelt es sich um eine Übersicht der Avaldaten. Das Aval mit ' +
            'rechtsverbindlichem Inhalt können Sie in der PDF-Ansicht einsehen.',
          pdfAnsicht: 'Zur PDF-Ansicht wechseln',
        },
      },
      views: {
        erstellen: {
          steps: {
            daten: 'Avaldaten',
            textauswahl: 'Textauswahl',
            pruefung: 'Prüfen',
          },
          contentHeader: 'Aval ausstellen',
          daten: {
            contentHeader: 'Avaldaten eingeben',
            beschreibung: 'Bitte geben Sie sämtliche Daten zum Aval ein.',
            gruppen: {
              aval: {
                contentHeader: 'Aval',
                betrag: 'Betrag in EUR',
                avalart: 'Avalart',
              },
              vertrag: {
                contentHeader: 'Grundgeschäft',
                vertragsdatum: 'Vertragsdatum',
                vertragsnummer: 'Vertragsnummer',
              },
              laufzeit: {
                contentHeader: 'Laufzeit',
                befristungsDatum: 'Befristungsdatum',
                ablaufDatum: 'Vorauss. Ablaufdatum (optional)',
              },
              artDerArbeit: {
                contentHeader: 'Leistung',
                platzhalter: 'Bitte beschreiben Sie die Leistung',
              },
              ortDerArbeit: {
                contentHeader: 'Ort der Leistung',
                platzhalter: 'Bitte beschreiben Sie den Ort der Leistung',
              },
            },
          },
          pruefung: {
            contentHeader: 'Eingaben prüfen',
            ausstellen: 'Jetzt ausstellen',
            hinweise: {
              headerText:
                'Bitte überprüfen Sie unten die von Ihnen erfassten Avaldaten. Tragen Sie ' +
                'anschließend die Avalnummer und das Ausstellungsdatum ein.',
              footerText: {
                ueberTrustlog:
                  'Nachdem Sie das Aval ausgestellt haben, wird dieses dem Begünstigten über ' +
                  'Trustlog zugestellt',
                herunterladen: 'Nach der Ausstellung können Sie das Avaldokument herunterladen.',
              },
              unbekannterBeguenstigter: {
                header: 'Das Aval wurde erstellt und kann versendet werden.',
                text:
                  'Bitte laden Sie das Aval für { beguenstigterName } inklusive ' +
                  'Enthaftungsschreiben, Infoschreiben und Siegel als PDF-Datei zur Weitergabe an ' +
                  'den Empfänger herunter.',
              },
            },
            fehler: 'Bitte füllen Sie alle erforderlichen Felder aus',
            gruppen: {
              ausstellungsdaten: {
                contentHeader: 'Austellungsdaten',
                nummer: 'Avalnummer',
                ausstellungsDatum: 'Ausstellungsdatum',
              },
            },
          },
          textauswahl: {
            banner: {
              erzeugenUndHochladen:
                'Sie müssen das Avaldokument in Ihrem eigenen System erzeugen und hier hochladen.',
              keinBefristungsdatum: 'Der ausgewählte Text lässt kein Befristungsdatum zu.',
              textAuswahl:
                'Der Begünstigte wird darüber informiert, dass nicht sein in Trustlog ' +
                'hinterlegter Text verwendet wird. Diese Textwahl führt daher zu mehr Prüfaufwand ' +
                'seitens des Begünstigten.',
              ungepruefterTextOhneAufgabe: {
                header: 'Der Text wartet darauf, geprüft zu werden.',
                text: 'Der ausgewählte Begünstigten-Text muss vor seiner Verwendung geprüft und freigegeben werden.',
                beschreibungText:
                  '<b>Achtung:</b> Mit Klick auf den Button wird der Ausstellungsprozess abgebrochen und\n' +
                  'berechtigte Nutzer über die neue Prüfaufgabe informiert.',
                button1Text: 'Prüfaufgabe erstellen',
              },
              ungepruefterTextMitAufgabe: {
                header: 'Der Text befindet sich in Prüfung',
                text:
                  'Der ausgewählte Begünstigten-Text muss vor seiner Verwendung geprüft und ' +
                  'freigegeben werden. Berechtigte Nutzer wurden bereits über die neue Prüfaufgabe ' +
                  'informiert.',
              },
            },
            contentHeader: 'Text auswählen',
            beschreibung: {
              auswaehlen: 'Wählen Sie den Avaltext aus, der verwendet werden soll.',
              hochladen: 'Bitte laden Sie das fertig erstellte Avaldokument hoch.',
            },
            avaltexte: {
              contentHeader: 'Avaltext',
              optionen: {
                hinterlegterText: 'Vom Begünstigten hinterlegten Text verwenden',
                andererText: 'Einen anderen Text verwenden...',
                nichtEmpfohlen: ' (nicht empfohlen)',
              },
              textart: 'Textart',
              oeffentlicherTextart: 'Art des öffentlichen Textes',
            },
            textvorschau: 'Textvorschau',
            wirdGeladen: 'Das Dokument wird geladen...',
          },
          vertragspartner: {
            gruppen: {
              vertragspartner: 'Vertragspartner',
              kreditnehmer: 'Kreditnehmer',
              beguenstigter: 'Begünstigter',
            },
            fehler: {
              vertragspartner: 'Bitte wählen Sie Vertragspartner',
              kreditnehmer: 'Bitte wählen Sie einen Kreditnehmer',
              beguenstigter: 'Bitte wählen Sie einen Begünstigten',
            },
            suche: {
              hinzufuegenLabel: {
                kreditnehmer: 'Daten manuell eingeben',
                beguenstigter: 'Daten manuell eingeben',
              },
              suchLabel: {
                kreditnehmer: 'Kreditnehmer',
                beguenstigter: 'Begünstigter',
              },
            },
            eingabe: {
              typ: {
                firma: 'Firma',
                oeffentlich: 'Öffentlich',
                privatperson: 'Privatperson',
              },
              suchButton: 'zurück zur Suche',
              name: 'Vorname und Nachname',
              nameLautHandelsregister: 'Name laut Handelsregister',
              gruppe: {
                kreditnehmer: 'Kreditnehmer',
                beguenstigter: 'Begünstigter',
              },
            },
          },
          validierung: {
            fehlermeldung: {
              gleicheOrganisation:
                'Begünstigter und Kreditnehmer dürfen nicht der gleichen Organisation angehören',
            },
          },
        },
        korrigieren: avalKorriegernWorkflow,
        zweitpruefung: avalZweitpruefungWorkflow,
      },
    },
    auftraege: {
      liste: {
        contentHeader: 'Aufträge',
        suchPlaceholder: 'Suche nach Auftragsnummer oder Begünstigten',
        aktionen: {
          listExportieren: 'Liste exportieren',
        },
        filter: {
          label: [
            'Bürge',
            'Auftragnehmer',
            'Auftraggeber',
            'Avalart',
            'Erstellt',
            'Betrag',
            'Status',
          ],
        },
        spalten: {
          kreditnehmer: 'Kreditnehmer',
          beguenstigter: 'Begünstigter',
          avalart: 'Avalart',
          erstellt: 'Erstellt',
          betrag: 'Betrag',
          status: 'Status',
        },
        defaultEmptyStateText:
          'Hier werden Ihre Avalaufträge angezeigt, sobald Sie den ersten Auftrag über Trustlog erstellt haben.',
      },
      uebersicht: {
        contentHeader: 'Auftrag im Detail',
        erfolgBannerText:
          'Der Auftrag wurde abgelehnt. Der Kreditnehmer wurde über die Ablehnung informiert.',
        warnungBannerText: 'Dieser Auftrag wartet darauf, geprüft zu werden.',
        warnungBannerTextAvalentwurf: 'Dieser Auftrag wartet auf Zweitprüfung.',
        warnungBannerButton: 'Auftrag prüfen',
        warnungBannerButtonAvalentwurfPruefen: 'Avalentwurf prüfen',
        warnungBannerButtonAvalentwurfOeffnen: 'Avalentwurf öffnen',
        infobar: {
          auftragsnummer: 'Auftrag',
          vertragsnummer: 'Vertrag',
          ansprechpartner: 'Ansprechpartner',

          ansprechpartnerModal: {
            heading: 'Ansprechpartner beim Kreditnehmer',
          },
        },
        betrag: 'Avalbetrag',
        kreditnehmer: 'Kreditnehmer',
        beguenstigter: 'Begünstigter',
        grundgeschaeft: 'Grundgeschäft',
        leistungsbeschreibung: 'Leistung',
        ortDerLeistung: 'Ort der Leistung',
        mitDemMuster: 'Avalauftrag mit der Textvorlage',
        weitereAvaleigenschaften: 'Weitere Avaleigenschaften',
        zahlungAufErstesAnfordern: 'Zahlung auf erstes Anfordern',
        aktionen: {
          auftragPruefen: 'Auftrag prüfen',
          avalOeffnen: 'Aval öffnen',
          avalentwurfOeffnen: 'Avalentwurf öffnen',
          datenHerunterladen: 'Auftrag herunterladen',
          csvExportieren: 'Als CSV exportieren',
          auftragAbgelehntModalOeffnen: 'Ablehnungsgrund anzeigen',
        },
        modale: {
          auftragAbgelehntHinweis: {
            body: {
              hinweisHeading: 'Ablehnungsgrund',
              ansprechpartnerHeading: 'Ansprechpartner',
            },
            heading: 'Details zur Ablehnung',
          },
        },
      },
      workflows: {
        pruefen: pruefenWorkflow,
      },
    },
    buergschaftstexte: {
      liste: {
        contentHeader: 'Texte',
        tab: {
          beguenstigtentexte: 'Begünstigtentexte',
          avalgebertexte: 'Avalgebertexte',
          oeffentlichetexte: 'Öffentliche Texte',
        },
        suchPlaceholder: 'Suche nach Name oder ID des Begünstigten',
        filter: {
          label: ['Avalart', 'Eingegangen', 'Gültig Ab', 'Status'],
          filterOptions: {
            avalart: [
              'Mängelansprüchebürgschaft',
              'Vertragserfüllungsbürgschaft',
              'An- & Vorauszahlungsbürgschaft',
              'Ausführungsbürgschaft',
              'Anzahlungen',
              'Arbeitszeitkontenbürgschaft',
              'Auslandsbürgschaft',
              'Ausschüttungsbürgschaft',
              'Bauhandwerkersicherungsbürgschaft',
              'Bietungsbürgschaft',
              'BImSchG-Bürgschaft',
              'Branntweinbürgschaft',
              'Deckungs-/Haftungsrücklass',
              'Deckungsrücklass',
              'Erschließungsbürgschaft',
              'Fertigstellungs-/Haftungsrücklass',
              'Fertigstellungsgarantie',
              'Frachtbürgschaft',
              'Garantie',
              'Gewährleistungsgarantie',
              'Haftungsrücklass',
              'IATA-Bürgschaft',
              'Lieferantenbürgschaften',
              'MaBV-Bürgschaft',
              'Mietbürgschaft',
              'Mineralölbürgschaft',
              'Pachtbürgschaft',
              'Prozessbürgschaft',
              'Rekultivierungsbürgschaft',
              'Rückbürgschaft',
              'Sonstige Bürgschaft',
              'Sonstige Garantie',
              'Tankkartenbürgschaft',
              'Vertragserfüllungsgarantie',
              'Zahlungsbürgschaft',
              'Zollbürgschaft',
              'Allgemeine Bürgschaft',
            ],
            status: [
              'In Bearbeitung',
              'Aktiv',
              'In Prüfung',
              'Abgelehnt',
              'Archiviert',
              'Hochgeladen',
              'Angelegt',
            ],
          },
        },
        spalten: {
          beguenstigter: 'Begünstigter / Bezeichnung',
          avalart: 'Avalart',
          textId: 'Text-ID',
          gueltigAb: 'Gültig ab',
          status: 'Status',
          textbezeichnung: 'Textbezeichnung',
        },
      },
      uebersicht: {
        contentHeader: {
          auftraggebertext: 'Begünstigtentext im Detail',
          neuePruefung: 'Neuen {textart} prüfen',
          zweitPruefung: 'Zweitprüfung {textart}',
          buergentext: 'Avalgebertext im Detail',
          oeffentlicherText: 'Öffentlicher Text im Detail',
        },
        banner: {
          header: 'Konflikt beim Bearbeiter',
          text: 'Die Erst- und Zweitprüfung für einen Begünstigtentext kann nicht durch den selben Nutzer erfolgen!',
        },
        allgemeineAngaben: 'Allgemeine Angaben',
        status: 'Status',
        gueltigAb: 'Gültig Ab',
        textId: 'Text-Id',
        bezeichnung: 'Bezeichnung',
        dmsId: 'Dms-Id Template',
        zahlungAufErstesAnfordern: 'Zahlung auf erstes Anfordern',
        zahlungAufErstesAnfordernMoeglich: 'möglich',
        zahlungAufErstesAnfordernNichtMoeglich: 'nicht möglich',
        beguenstigter: 'Begünstigter',
        details: 'Details',
        befristung: 'Befristung',
        befristet: 'Befristet',
        unbefristet: 'Unbefristet',
        aktionen: {
          akzeptieren: 'Akzeptieren',
          ablehnen: 'Ablehnen',
          pdfExportieren: 'Als PDF exportieren',
          herunterladen: 'Beschlussdokumentation herunterladen',
        },
        historie: {
          hochladen: '{bearbeiter} hat die Textvorlage hochgeladen.',
          erstellen: '{bearbeiter} hat den Text erstellt.',
          archiviert: '{bearbeiter} hat den Text archiviert.',
          akzeptiert: '{bearbeiter} hat den Text {link} akzeptiert.',
          mitKommentar: 'mit Kommentar',
          abgelehnt: '{bearbeiter} hat den Text {link} zurückgewiesen.',
          zurueckweisen: '{bearbeiter} hat den Text {link} zurückgewiesen.',
          freigegeben: '{bearbeiter} hat den Text {link} freigegeben.',
          kommentar: {
            title: 'Kommentar',
            internerKommentar: 'Interne Kommentare',
            externerKommentar: 'Kommentar an den Begünstigten',
          },
        },
        pruefenSnackbar: {
          erstpruefung: 'Neuen Text prüfen',
        },
      },
      pruefung: {
        title: 'Interner Kommentar',
        kommentarPlaceholder: 'Kommentar verfassen ...',
        erstpruefung: {
          akzeptieren: {
            beschreibung:
              'Bitte fügen Sie einen internen Kommentar zum Begünstigtentext hinzu. Anschließend wird der Text zur Zweitprüfung weitergeleitet.',
            kommentarPlaceholder:
              'Hier können Sie einen Kommentar an den weiteren Prüfer verfassen ...',
            primaryButton: 'Hinzufügen & abschließen',
          },
          ablehnen: {
            beschreibung:
              'Bitte geben Sie an, warum Sie den Begünstigtentext ablehnen. Anschließend wird der Text an den Zweitprüfer weitergeleitet.',
            title: 'Interner Kommentar (nicht editierbar)',
            KommentaPlaceholder: 'Bitte verfassen Sie einen Kommentar an den Zweitprüfer...',
            kommentarAnAgTitle: 'Nachricht an den Begünstigten (editierbar vom Zweitprüfer)',
            kommentarAnAgPlaceholder: 'Bitte verfassen Sie eine Nachricht an den Begünstigten...',
            primaryButton: 'Weiterleiten',
          },
        },
        zweitpruefung: {
          akzeptieren: {
            beschreibung:
              'Bitte fügen Sie einen internen Kommentar zum akzeptierten Begünstigtentext hinzu.',
            primaryButton: 'Text freigeben',
          },
          ablehnen: {
            kontaktdatenTitle: 'Kontaktdaten des Ansprechpartners (optional)',
            kontaktdatenBeschreibung:
              'Bitte nennen Sie einen Ansprechpartner, an den sich der {rolle} bei Rückfragen wenden ' +
              'kann.',
            beschreibung:
              'Bitte geben Sie an, warum Sie den Begünstigtentext ablehnen. Der vorbereitete ' +
              'Kommentar des Erstprüfers kann übernommen und/oder editiert an den Begünstigten ' +
              'gesendet werden.',
            kommentarAnAgTitle: 'Kommentar an den Begünstigten (editierbar)',
          },
        },
        erfolgScreen: {
          erstpruefung:
            'Sie haben den Begünstigtentext {entscheidung} und zur Zweitprüfung weitergeleitet.',
          zweitpruefung: 'Sie haben den Begünstigtentext {entscheidung}.',
        },
      },
    },
    nutzerundrollen: {
      nutzer: {
        aenderung: {
          status: 'Durch Aktivieren ist der Nutzer berechtigt, digitale Avale zu verwalten.',
        },
      },
      rollen: {
        rechtegruppe: {
          anträge: 'Anträge',
          bürgschaften: 'Avale',
          aufgaben: 'Aufgaben',
          mitteilungen: 'Mitteilungen',
          projekte: 'Projekte',
          unternehmen: 'Teilnehmer',
          verträge: 'Avalkreditverträge',
          texte: 'Texte',
          nutzer: 'Nutzer',
          rollen: 'Rollen',
        },
        rechte: {
          dokumenthochladen: 'Avaldokument hochladen',
        },
      },
    },
    profil: {
      nutzerstatus: {
        beschreibungBerechtigung:
          'Mit einem aktiven Status sind Sie berechtigt die Avalplattform zu nutzen.',
        beschreibungDeaktivierung:
          'Durch Deaktivierung haben Sie keinen Zugriff mehr auf die Plattform.',
      },
    },
    unternehmen: {
      liste: {
        contentHeader: 'Teilnehmer',
        suchPlaceholder: 'Alle Teilnehmer durchsuchen',
        spalten: {
          teilnehmer: 'Teilnehmer',
          teilnehmerId: 'Teilnehmer-ID',
        },
        aktionen: {
          exportieren: 'Liste exportieren',
        },
        defaultEmptyStateText:
          'Es sieht so aus, als wurden noch keine weiteren Unternehmen hinzugefügt.',
      },
      uebersicht: {
        contentHeader: 'Teilnehmer-ID',
        aktionen: {
          avalErstellen: 'Aval ausstellen',
        },
        detailansicht: {
          ueberschrift: 'Stammdaten',
          name: 'name des teilnehmers',
          gesellschaftsform: 'gesellschaftsform',
          handelsregisternummer: 'handelsregisternummer',
          handelsregister: 'handelsregister',
        },
        tabelle: {
          ueberschrift: {
            mutterUnternehmen: 'Trustlog-Vertragspartner',
            tochterUnternehmen: 'Tochterunternehmen',
          },
        },
      },
    },
    vertraege: {
      vertragsnummer: 'Vertragsnummer',
      kreditnehmer: 'Kreditnehmer',
      aktivierungsstatus: 'Status',
      allgemeineAngaben: 'Allgemeine Angaben',
      verknuepfungenInPruefung: 'Verknüpfungen in Prüfung',
      verknuepfteUnternehmen: 'Verknüpfte Unternehmen',
      abgelehnteVerknuepfungen: 'Abgelehnte Verknüpfungen',
      dokumentationHerunterladen: 'Dokumentation herunterladen',
      vertragszuordnungPruefen: 'Vertragszuordnung prüfen ',
      zuVerknuepfendeUnternehmen: 'Zu verknüpfende Unternehmen',
      bitteAlleUnternehmenAuswaehlen:
        'Bitte wählen Sie alle Unternehmen aus, welche zu dem Vertrag gehören.',
      neueVertragzuordnungPruefen: 'Neue Vertragzuordnung prüfen',
      neueVertragzuordnungGeprueft: 'Neue Vertragszuordnung geprüft',
      vertragsdetails: 'Vertragsdetails',
      internerKommentar: 'Interner Kommentar (optional)',
      KommentarAnDenKreditnehmer: 'Kommentar an den Kreditnehmer',
      KommentarVerfassen: 'Kommentar verfassen ...',
      unternehmenAusgewaehlt: 'Unternehmen ausgewählt',
      zuDenVertraegen: 'Zu den Verträgen',
      moeglichkeitKommentarErfassen:
        'Sie haben die Möglichkeit einen Kommentar zur Freigabe der weiteren Unternehmen zu erfassen.',
      zuordnung: {
        aendern: {
          ueberschrift: {
            aenderung: 'Warum möchten Sie die Verknüpfungen ändern?',
            ablehnung: 'Warum möchten Sie die weiteren Verknüpfungen ablehnen?',
          },
          beschreibung: {
            aenderung:
              'Sie können in den Kommentarfeldern Ihre abgeänderte Unternehmensauswahl begründen.',
            ablehnung:
              'Sie können in den Kommentarfeldern begründen, warum Sie weitere Verknüpfungen ablehnen.',
          },
        },
        pruefenErfolg: {
          beschreibung: {
            akzeptiert: 'Zuordnung der weiteren Unternehmen wurde freigegeben.',
            abgelehnt: 'Zuordnung der weiteren Unternehmen wurde abgelehnt.',
          },
          subbeschreibung: {
            akzeptiert:
              'Sie haben die Zuordnung der weiteren Unternehmen bestätigt und diese freigegeben.',
            abgelehnt:
              'Der Kreditnehmer wird über Ihre Entscheidung informiert und wird bei Bedarf mit Ihnen in Verbindung treten.',
          },
        },
      },
      liste: {
        contentHeader: 'Avalkreditverträge',
        filter: {
          label: ['Bürge', 'Kreditnehmer', 'Status'],
        },
      },
      details: {
        contentHeader: 'Details zum Vertrag',
      },
    },
  },
  trustlog: {
    aufgaben: {},
    buergschaften: {},
    buergschaftsantraege: {},
    buergschaftstexte: {},
    nutzer: {},
    stammdaten: {},
  },
  designSystem: {
    fehler: {
      uploadAvaldokument: 'Bitte laden Sie ein neues Avaldokument hoch',
    },
    molecules: {
      avaldatenBearbeiten: {
        gruppen: {
          kreditnehmer: 'Kreditnehmer',
          beguenstigter: 'Begünstigter',
          aval: 'Avaldaten',
          vertrag: 'Grundgeschäft',
          laufzeit: 'Laufzeit',
          artDerArbeit: 'Leistung',
          ortDerArbeit: 'Ort der Leistung',
          text: 'Text',
          avaldokument: 'Avaldokument',
        },
        textBearbeitet: 'bearbeitet',
        bearbeitenButton: 'Bearbeiten',
        partials: {
          artDerArbeit: {
            placeholder: 'Bitte beschreiben Sie die Leistung...',
          },
          beguenstigter: {
            optionen: {
              firma: 'Firma',
              oeffentlich: 'Öffentlich',
              privatperson: 'Privatperson',
            },
          },
          base: {
            fehler: 'Passen Sie mindestens einen Wert an oder brechen Sie die Bearbeitung ab',
          },
          aval: {
            avalart: 'Avalart',
            betrag: 'Betrag in EUR',
            ablaufdatum: 'Voraussichtl. Ablaufdatum (optional)',
          },
          laufzeit: {
            befristungsDatum: 'Befristungsdatum',
          },
          ortDerArbeit: {
            placeholder: 'Bitte beschreiben Sie hier den Ort der Arbeit möglichst genau …',
          },
          vertrag: {
            vertragsDatum: 'Vertragsdatum',
          },
          validierung: {
            betrag: 'Bitte geben Sie einen gültigen Betrag ein',
          },
        },
      },
      vertragspartner: {
        suche: {
          hinzufuegenLabel: {
            kreditnehmer: 'Daten manuell eingeben',
            beguenstigter: 'Daten manuell eingeben',
          },
          suchLabel: {
            kreditnehmer: 'Kreditnehmer',
            beguenstigter: 'Begünstigter',
          },
        },
        gruppe: {
          aufTrustlogSuchen: 'Auf Trustlog suchen',
          manuellEingeben: 'Zur manuellen Eingabe',
        },
      },
    },
  },
};

export default deLocale;
