/**
 * Bündelt alle Daten zum Partnersystem. Also Unternehmen, Personen wie Ansprechpartner (nicht Nutzer!) sowie ihrer
 * grundlegenden Datentypen wie Telefonnummer etc.
 * Nutzer (die in Keycloak registriert sind) werden in Nutzer.ts gepflegt, referenzieren aber auch hierauf (z.B. für Telefonnummer)
 */

import type { Buerge } from '@/shared/Buergen/types';
import type { Ansprechpartner } from '@/types/Bürgschaft';
import { ladeLaenderStorage } from '@/persistence/LänderStorage';
import type { PreisModell } from '@/shared/useFeatureToggles/preisModell';

/** Der Auftraggeber ist der Gläubiger im Bürgschaftsvertrag */
export interface BasisUnternehmen {
  unternehmenId: string;
  name: string;
  postadresse: Postadresse;
  ansprechpartner?: Ansprechpartner;
}

/** Ganz abstrakt: eine Firma. Kann in der BSP ein Auftraggeber oder ein Bürge sein. Wichtig: Nicht der Nutzer vor dem Bildschirm, sondern die Organisation, für die er arbeitet */
export interface Unternehmen extends BasisUnternehmen {
  version: number;
  gesellschaftsform: Gesellschaftsform;
  handelsregisternummer: string;
  handelsregister: string;
  person: Person;
  mutterUnternehmen: Unternehmen;
  mutterUnternehmenId: string;
  tochterUnternehmen: Unternehmen[];
  unternehmensstatus: Unternehmensstatus;
  hybrid: boolean;
  oeffentlicherAuftraggeber: boolean;
  bestaetigungsanfrageBuerge: boolean;
  inkognito: boolean;
  beantragen: boolean;
  erstelltAm: string;
  fremdId?: string;
  preisModell?: keyof typeof PreisModell;
}

export interface BürgeAnzeigedaten {
  bürge: Buerge;
  cssHeaderColor: string;
  cssHeaderFontColor: string;
}

/**
 * Repräsentiert eine Vertragsart zwischen Bürge und Trustlog
 */

export enum Vertragsart {
  LIGHT = 'LIGHT',
  REGULAR = 'REGULAR',
}

export enum BuergeBausummeAngabe {
  BETRAG = 'BETRAG',
  PROZENT = 'PROZENT',

  /*
   * Die Angabe wird später gemacht, während Bearbeitung der Daten z.B. bei der Anlage von Bürge-Light. Dieser Wert
   * wird benutzt in den Fällen, wenn am Anfang des Prozesses man noch nicht weiß welcher Wert benutzt werden soll.
   */
  KEINE_ANGABE = 'KEINE_ANGABE',
}

/** Quasi-Konstruktor für ein Postadresse-Objekt */
export function createEmptyPostadresse(): Postadresse {
  return {
    straße: '',
    hausnummer: '',
    postleitzahl: '',
    ort: '',
    landeskennzeichen: 'DE',
  };
}

/**
 * Eine Person wird im Kontext eines anderen Domänenobjekts erfasst - z.B. zu einem Unternehmen. Der Personentyp gibt
 * dabei an, welche Funktion diese Person in diesem Kontext hat.
 */
export enum Personentyp {
  /** Kontaktperson */
  KP = 'KP',

  /** Vertretungsberechtigter */
  VB = 'VB',

  /** Bauleiter */
  BL = 'BL',

  /** Bauherr */
  BH = 'BH',
}

/** Quasi-Konstruktor für ein Person-Objekt */
export function createEmptyPerson(personentyp: Personentyp): Person {
  return {
    persId: null,
    vorname: '',
    nachname: '',
    telefonnummer: '',
    email: '',
    personentyp,
  };
}

export function createEmptyBürge(): Buerge {
  return {
    unternehmenId: null,
    version: null,
    name: '',
    gesellschaftsform: null,
    postadresse: createEmptyPostadresse(),
    handelsregisternummer: '',
    handelsregister: '',
    person: createEmptyPerson(Personentyp.VB),
    unternehmensstatus: null,
    erstelltAm: null,
    kurzname: '',
    verwendungBürgschaftstexte: false,
    vertragsart: Vertragsart.LIGHT,
    initialen: '',
    bausummeAngabe: null,
    lieferkanalPdf: null,
    type: 'BuergeDto',
  };
}

export function createEmptyBürgeAnzeigedaten(): BürgeAnzeigedaten {
  return {
    bürge: createEmptyBürge(),
    cssHeaderColor: '',
    cssHeaderFontColor: '',
  };
}

/** Quasi-Konstruktor zur Erzeugung eines neuen Unternehmen-Objekts - ohne Vertretungsberechtigtem */
export function createEmptyUnternehmen(): Unternehmen {
  return {
    unternehmenId: null,
    version: null,
    name: '',
    fremdId: '',
    gesellschaftsform: null,
    postadresse: createEmptyPostadresse(),
    handelsregisternummer: '',
    handelsregister: '',
    person: null,
    mutterUnternehmen: null,
    mutterUnternehmenId: '',
    tochterUnternehmen: [],
    unternehmensstatus: null,
    inkognito: false,
    hybrid: false,
    oeffentlicherAuftraggeber: false,
    bestaetigungsanfrageBuerge: false,
    erstelltAm: null,
    beantragen: false,
  };
}

/** Quasi-Konstruktor zur Erzeugung eines neuen Unternehmen-Objekts - fügt einen leeren Vertretungsberechtigten hinzu */
export function createEmptyUnternehmenMitVertretungsberechtigtem(): Unternehmen {
  const unternehmen = createEmptyUnternehmen();
  unternehmen.person = createEmptyPerson(Personentyp.VB);

  return unternehmen;
}

/** Rechtliche Gesellschaftsform des Unternehmens */
export enum Gesellschaftsform {
  AÖR = 'AÖR',
  AG = 'AG',
  ARGE = 'ARGE',
  BEHOERDE = 'BEHOERDE',
  EG = 'EG',
  EK = 'EK',
  EU = 'EU',
  EV = 'EV',
  GBR = 'GBR',
  GMBH = 'GMBH',
  GMBH_UND_CO_KG = 'GMBH_UND_CO_KG',
  KG = 'KG',
  KG_AA = 'KG_AA',
  KÖR = 'KÖR',
  OHG = 'OHG',
  SA = 'SA',
  SARL = 'SARL',
  SE = 'SE',
  SE_UND_CO_KG = 'SE_UND_CO_KG',
  STIFTUNG_UND_CO_KG = 'STIFTUNG_UND_CO_KG',
  UG = 'UG',
  UG_UND_CO_KG = 'UG_UND_CO_KG',
  VVAG = 'VVAG',
  WEG = 'WEG',
}

/** Bildet die Gesellschaftsform auf einen Anzeigetext ab */
export const GesellschaftsformAnzeigetextMap: Map<Gesellschaftsform, string> = new Map([
  [Gesellschaftsform.AÖR, 'AöR'], // Anstalt des öffentlichen Rechts
  [Gesellschaftsform.AG, 'AG'], // Aktiengesellschaft
  [Gesellschaftsform.ARGE, 'ARGE'], // Arbeitsgemeinschaft
  [Gesellschaftsform.BEHOERDE, 'Behörde'], // Behörde
  [Gesellschaftsform.EU, 'Einzelunternehmen'], // Einzelunternehmen
  [Gesellschaftsform.EG, 'eG'], // eingetragene Genossenschaft
  [Gesellschaftsform.EK, 'e. K.'], // eingetragener Kaufmann
  [Gesellschaftsform.EV, 'e. V.'], // eingetragener Verein
  [Gesellschaftsform.GBR, 'GbR'], // Gesellschaft bürgerlichen Rechts
  [Gesellschaftsform.GMBH, 'GmbH'], // Gesellschaft mit beschränkter Haftung
  [Gesellschaftsform.GMBH_UND_CO_KG, 'GmbH & Co. KG'], // Gesellschaft mit beschränkter Haftung & Compagnie Kommanditgesellschaft
  [Gesellschaftsform.KG, 'KG'], // Kommanditgesellschaft
  [Gesellschaftsform.KG_AA, 'KGaA'], // Kommanditgesellschaft auf Aktien
  [Gesellschaftsform.KÖR, 'KöR'], // Körperschaft des öffentlichen Rechts
  [Gesellschaftsform.OHG, 'OHG'], // Offene Handelsgesellschaft
  [Gesellschaftsform.SA, 'S.A.'], // Sociedad Anónima
  [Gesellschaftsform.SARL, 'S.à r.l.'], // Société à responsabilité limitée
  [Gesellschaftsform.SE, 'SE'], // Europäische Gesellschaft
  [Gesellschaftsform.SE_UND_CO_KG, 'SE & Co. KG'], // SE & Co. Kommanditgesellschaft
  [Gesellschaftsform.STIFTUNG_UND_CO_KG, 'Stiftung & Co. KG'], // Stiftung & Co. Kommanditgesellschaft
  [Gesellschaftsform.UG, 'UG (haftungsbeschränkt)'], // Unternehmergesellschaft (haftungsbeschränkt)
  [Gesellschaftsform.UG_UND_CO_KG, 'UG (haftungsbeschränkt) & Co. KG'], // Unternehmergesellschaft (haftungsbeschränkt)
  [Gesellschaftsform.VVAG, 'VVaG'], // Versicherungsverein auf Gegenseitigkeit
  [Gesellschaftsform.WEG, 'WEG'], // Wohnungseigentümergemeinschaft
]);

/** Status, die ein Unternehmen durchläuft */
export enum Unternehmensstatus {
  /** Ungeprüft */
  UP = 'UP',

  /** Aktiv */
  AKT = 'AKT',

  /** Deaktiviert/Inaktiv */
  DAK = 'DAK',
}

export enum UnternehmensstatusVerträge {
  /** Ungeprüft */
  UP = 'UP',

  /** Aktiv */
  AKT = 'AKT',
}

/** Bildet den Status eines Unternehmens auf einen Anzeigetext ab */
export const UnternehmenStatusAnzeigetextMap: Map<Unternehmensstatus, string> = new Map([
  [Unternehmensstatus.UP, 'Ungeprüft'],
  [Unternehmensstatus.AKT, 'Aktiv'],
  [Unternehmensstatus.DAK, 'Inaktiv'],
]);

export const UnternehmenStatusVerträgeAnzeigetextMap: Map<UnternehmensstatusVerträge, string> =
  new Map([
    [UnternehmensstatusVerträge.UP, 'Ungeprüft'],
    [UnternehmensstatusVerträge.AKT, 'Aktiv'],
  ]);

/** Eine Postadresse */
export interface Postadresse {
  straße: string;
  hausnummer: string;
  postleitzahl: string;
  ort: string;
  landeskennzeichen: string;
}

/** Eine natürliche Person, die im Kontext des Partnersystems einen Typ annehmen kann, wie z.B. Vertretungsberechtigter */
export interface Person {
  persId: number;
  vorname: string;
  nachname: string;
  telefonnummer: string;
  email: string;
  personentyp: Personentyp;
}

export interface Land {
  landeskennzeichen: string;
  vollerName: string;
  ländervorwahl: string;
}

export interface PersonInUnternehmen {
  person: Person;
  unternehmenId: string;
  version: number;
}

export function createEmptyPersonInUnternehmen(): PersonInUnternehmen {
  return {
    person: createEmptyPerson(Personentyp.KP),
    unternehmenId: '',
    version: 0,
  };
}

export interface EintrittskarteRequest {
  bürgschaftId: string;
  version: number;
  eintrittskartentyp: Eintrittskartentyp;
  personInUnternehmen: PersonInUnternehmen;
  hinweis: '';
  hashWert?: string;
}

export enum Eintrittskartentyp {
  /** Kann einsehen */
  EINSEHEN = 'EINSEHEN',

  /** Kommentieren */
  KOMMENTIEREN = 'KOMMENTIEREN',

  /** Freigabe prüfen */
  FREIGABE_PRUEFEN = 'FREIGABE_PRUEFEN',
}

export function asLand(landeskennzeichen: string): Land {
  const länderliste: Land[] = ladeLaenderStorage().länder;

  const gesuchtesLand = länderliste.find((land) => land.landeskennzeichen === landeskennzeichen);

  if (gesuchtesLand) {
    return gesuchtesLand;
  }
  return null;
}

export function getVollerNameFromLandeskennzeichen(landeskennzeichen: string): string {
  const land = asLand(landeskennzeichen);
  if (land) {
    return land.vollerName;
  }
  return null;
}

/** Filtert die übergebenen Bürgen hinsichtlicht verwendungBürgschaftstexte == true */
export function getBuergenMitVerwendungTexte(bürgen: Buerge[]): Buerge[] {
  if (bürgen) {
    return bürgen.filter((b) => b.verwendungBürgschaftstexte);
  }
  return [];
}

/** Filtert die übergebenen Bürgen hinsichtlicht verwendungBürgschaftstexte == true und mappt auf deren Unternehmen-IDs */
export function getBürgenIDsMitVerwendungTexte(bürgen: Buerge[]): string[] {
  return getBuergenMitVerwendungTexte(bürgen).map((b) => b.unternehmenId);
}

/** Baut eine Zeile mit Postleitzahl und Ort auf */
export function getPlzOrt(postadresse: Postadresse): string {
  if (!postadresse) {
    return '';
  }

  let feld1 = postadresse.postleitzahl;
  let feld2 = postadresse.ort;

  if (feld1 === null) {
    feld1 = '';
  } else {
    feld1 += ' ';
  }
  if (!feld2) {
    feld2 = '';
  }
  return feld1.concat(feld2);
}

export function getPlzOrtOhnePostadresse(postleitzahl: string, ort: string): string {
  let feld1 = postleitzahl;
  let feld2 = ort;

  if (feld1 === null) {
    feld1 = '';
  } else {
    feld1 += ' ';
  }
  if (!feld2) {
    feld2 = '';
  }
  return feld1.concat(feld2);
}

export function isPostadresseBefüllt(postadresse: Postadresse): boolean {
  return (
    postadresse &&
    ((postadresse.straße && postadresse.straße.trim() !== '') ||
      (postadresse.hausnummer && postadresse.hausnummer.trim() !== '') ||
      (postadresse.postleitzahl && postadresse.postleitzahl.trim() !== '') ||
      (postadresse.ort && postadresse.ort.trim() !== ''))
  );
}

export function isPostadresseVollständigBefüllt(postadresse: Postadresse): boolean {
  return (
    postadresse &&
    postadresse.straße &&
    postadresse.straße.trim() !== '' &&
    postadresse.hausnummer &&
    postadresse.hausnummer.trim() !== '' &&
    postadresse.postleitzahl &&
    postadresse.postleitzahl.trim() !== '' &&
    postadresse.ort &&
    postadresse.ort.trim() !== ''
  );
}

export interface NutzerAnlagePruefErgebnis {
  koennteAngelegtWerden: boolean;
  nutzerExistiertInEigenerOrganisation: boolean;
  nutzerKannWiederhergestelltWerden: boolean;
}
