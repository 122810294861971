export enum BuergeRoutes {
  // Aufgaben
  BUERGE_AUFGABEN_LISTE = 'aufgabenListeBürge',

  // Antrag
  BUERGE_ANTRAG_DETAIL = 'buergschaftsauftragDetailsBuerge',
  // Antrag prüfen
  BUERGE_ANTRAG_PRUEFEN_AUFGABE_BASE = 'buerge-antrag-pruefen-aufgabe-base',
  BUERGE_ANTRAG_PRUEFEN_AUFGABE_DETAILS = 'buerge-antrag-pruefen-aufgabe-details',
  BUERGE_ANTRAG_PRUEFEN_AUFGABE_ENTSCHEIDUNG = 'buerge-antrag-pruefen-aufgabe-entscheidung',
  BUERGE_ANTRAG_PRUEFEN_AUFGABE_BEARBEITEN = 'buerge-antrag-pruefen-aufgabe-bearbeiten',
  BUERGE_ANTRAG_PRUEFEN_AUFGABE_AKZEPTIEREN = 'buerge-antrag-pruefen-aufgabe-akzeptieren',
  BUERGE_ANTRAG_PRUEFEN_AUFGABE_ABLEHNEN = 'buerge-antrag-pruefen-aufgabe-ablehen',
  // Bürgschaften
  BUERGE_BUERGSCHAFT_LIST = 'bürgschaftenListeBürge',

  BUERGE_BUERGSCHAFT_DETAIL = 'bürgschaftDetailsBürge',

  // Bürgschaft erstellen
  BUERGE_BUERGSCHAFT_ERSTELLEN = 'buerge-buergschaft-erstellen-base',
  BUERGE_BUERGSCHAFT_ERSTELLEN_DATEN = 'buerge-buergschaft-erstellen-daten',
  BUERGE_BUERGSCHAFT_ERSTELLEN_TEXT = 'buerge-buergschaft-erstellen-text',
  BUERGE_BUERGSCHAFT_ERSTELLEN_PRUEFEN = 'buerge-buergschaft-erstellen-pruefen',

  // Bürgschaft korrigieren
  BUERGE_BUERGSCHAFT_KORRIGIEREN = 'buerge-buergschaft-korrigieren-base',
  BUERGE_BUERGSCHAFT_KORRIGIEREN_KORREKTURWUNSCH = 'buerge-buergschaft-korrigieren-korekturwunsch',
  BUERGE_BUERGSCHAFT_KORRIGIEREN_BUERGSCHAFTSDATEN = 'buerge-buergschaft-korrigieren-daten',
  BUERGE_BUERGSCHAFT_KORRIGIEREN_PRUEFEN = 'buerge-buergschaft-korrigieren-pruefen',

  // Bürgschaft prüfen
  BUERGE_BUERGSCHAFT_PRUEFEN_AUFGABE_BASE = 'buerge-buergschaft-pruefen-aufgabe-base',
  BUERGE_BUERGSCHAFT_PRUEFEN_AUFGABE_DETAILS = 'buerge-buergschaft-pruefen-aufgabe-details',
  BUERGE_BUERGSCHAFT_PRUEFEN_AUFGABE_ENTSCHEIDUNG = 'buerge-buergschaft-pruefenn-aufgabe-entscheidung',
  BUERGE_BUERGSCHAFT_PRUEFEN_AUFGABE_AKZEPTIEREN = 'buerge-buergschaft-pruefen-aufgabe-akzeptieren',
  BUERGE_BUERGSCHAFT_PRUEFEN_AUFGABE_AKZEPTIEREN_MIT_AENDERUNG = 'buerge-buergschaft-pruefen-aufgabe-akzeptieren-mit-aenderung',
  BUERGE_BUERGSCHAFT_PRUEFEN_AUFGABE_ABLEHNEN = 'buerge-buergschaft-pruefen-aufgabe-ablehnen',
}
